import React from 'react';
import {
  faBars, faClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';

export const Navbar: React.FC = () => {
  // const history = useNavigate();
  const [click, setClick] = React.useState(false);
  // const [isLogin, setIsLogin] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };
  // const handleLogin = () => {
  //   setIsLogin(!isLogin);
  // };
  // const registerAgentPage = () => {
  //   history('/register');
  //   window.scrollTo(0, 0);
  // };
  // const loginAgentPage = () => {
  //   history('/login');
  //   window.scrollTo(0, 0);
  // };
  // const loginAdminPage = () => {
  //   history('/login');
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className="navbar">
      <NavLink to="/home" className="nav-logo">
        <img style={{ height: '50px', width: 'auto' }} src="/acps.png" alt="campusXcelLogo" />
      </NavLink>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        {/* <li className="nav-item">
          <NavLink
            className="nav-links margin-mobile-left"
            to="/home"
            title="Home"
            onClick={() => { history('/home'); }}
            // onClick={() => { window.scrollTo(0, 0); window.location.reload(); history('/home'); }}
          >
            <FontAwesomeIcon icon={faHome} className="nav-icon-link" /><span className="nav-text-header">Home</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-links margin-mobile-left"
            to="/#acps"
            title="ACPS"
            onClick={() => history('#acps')}
          >
            <FontAwesomeIcon icon={faGears} className="nav-icon-link" /><span className="nav-text-header">ACPS</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-links margin-mobile-left"
            to="/#about"
            title="About"
            onClick={() => history('#about')}
          >
            <FontAwesomeIcon icon={faBuildingUser} className="nav-icon-link" /><span className="nav-text-header">About us</span>
          </NavLink>
        </li>
        <li className="nav-item mr-2">
          <NavLink
            className="nav-links margin-mobile-left"
            to="/#connect"
            title="Contact Us"
            onClick={() => history('#connect')}
          >
            <FontAwesomeIcon icon={faPhone} className="nav-icon-link" /><span className="nav-text-header">Contact us</span>
          </NavLink>
        </li>
        <li className="nav-item-login mx-1">
          <Button
            className="acps-button-link mx-1"
            to="/#"
            type="button"
            title="Login to ACPS Portal"
            onClick={loginAdminPage}
          >
            Login
          </Button>
        </li>
        <li className="nav-item-login mx-1">
          <Button
            className="acps-button-oval mx-1"
            to="/#"
            type="button"
            title="Register to ACPS Portal"
            onClick={registerAgentPage}
          >
            Register
          </Button>
        </li> */}
      </ul>
      <NavLink
        className="nav-icon"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
    </div>
  );
};

export default Navbar;
