import { getCommonApiToken } from '../../utils';
import { getConfig } from '../../utils/config/config';
import { ListSubFolCountOptions, ListSubFolCountResponse } from './list-subFol-count.service.types';

const { YOCARD_API_ROOT } = getConfig();

export const ListSubFolCountRequest = ({
  requestType, userType, token, stDt, endDt, dist, printId,
}: ListSubFolCountOptions): Promise<ListSubFolCountResponse> => getCommonApiToken(`${YOCARD_API_ROOT}?userTp=${userType}&stDt=${stDt}&endDt=${endDt}&dist=${dist}&printId=${printId}`, requestType, token);

// export const ListSubFolCountRequest = ({
//   requestType, userType, token, stDt, endDt, dist, printId,
// }: ListSubFolCountOptions): Promise<ListSubFolCountResponse> => {
//   if (dist === '' && printId === '') {
//     return getCommonApiToken(`${YOCARD_API_ROOT}??userTp=${userType}&stDt=${stDt}&endDt=${endDt}&dist='NA'&printId='NA'`, requestType, token);
//   } if (dist === '' && printId !== '') {
//     return getCommonApiToken(`${YOCARD_API_ROOT}??userTp=${userType}&stDt=${stDt}&endDt=${endDt}&dist='NA'&printId=${printId}`, requestType, token);
//   } if (dist !== '' && printId === '') {
//     return getCommonApiToken(`${YOCARD_API_ROOT}??userTp=${userType}&stDt=${stDt}&endDt=${endDt}&dist=${dist}&printId='NA'`, requestType, token);
//   }
//   return getCommonApiToken(`${YOCARD_API_ROOT}??userTp=${userType}&stDt=${stDt}&endDt=${endDt}&dist=${dist}&printId=${printId}`, requestType, token);
// };
