import { SuperAdminTrendStsResponse, SuperAdminTrendStsResults } from './superadmin-trend-sts.service.types';

export const SuperAdminTrendStsTransform: (response: SuperAdminTrendStsResponse) => SuperAdminTrendStsResults = (superAdminTrendStsResults) => {
  const { data, is_error, message } = superAdminTrendStsResults;
  const result: SuperAdminTrendStsResults = {
    data: [
      {
        PrntId: '',
        PrntTp: '',
        cnt: '',
        cSts: '',
        updtOn: '',
        stData: {
          distNme: '',
          stCd: '',
          stNme: '',
        },
      },
    ],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};
