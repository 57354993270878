import React, { useState } from 'react';
import {
  Button,
  CardImg,
  Col, Input, Modal, ModalBody, Row, UncontrolledTooltip,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import './printer.scss';
import {
  faDownload, faFolder,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  cardStatusReq,
  clearAWSFilesUpload,
  clearCardStatusReq,
  clearGenPrintUrlReq,
  genPrintUrlReq,
  getAWSFilesUpload,
  getCardMassStatus,
} from '../../../store/yoCard/actions';
import { getConfig } from '../../../utils/config/config';
import LoaderData from '../../../utils/loader';
import { APIHeader, UserType } from '../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../utils/service/localstorage-service';
import { errorAlert, successAlert } from '../../../utils/alert';
import { PrintProps } from './print.type';
import { ArrayFileData } from '../../../services/upload-AWS-files/create-awsfiles.types';

const { AWS_CARD_ACTIVITY_BUCKET } = getConfig();

export const PrinterGeneratePrintFile: React.FC<PrintProps> = ({ printerData, userTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = useState('');
  // const [stateCode, setStateCode] = useState<string>('');
  const [distName, setDistName] = useState<string>('');
  const [distCode, setDistCode] = useState<string>('');
  const [dateData, setDateData] = useState<string>('');
  // const [failedCnt, setFailedCnt] = useState<number>(0);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);
  const [filePreview, setFilePreview] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<ArrayFileData[]>([]);
  const generatePrintZipResponse = useSelector((state: RootState) => state.yoCard.cardStatus);
  const genPrintUrlResponse = useSelector((state: RootState) => state.yoCard.genPrintUrl);
  // const updatePrintStatusResponse = useSelector((state: RootState) => state.yoCard.postPrintStatus);
  const uploadFilesResponse = useSelector((state: RootState) => state.yoCard.updateAWSFilesData);

  // const handleFieldChange = (e: any) => {
  //   setFailedCnt(e.target.value);
  // };

  const handleShowModelComplete = (subFolderData: string, districtCode: string, districtName: string) => {
    setDateData(subFolderData);
    setDistCode(districtCode);
    setDistName(districtName);
    // setStateCode(stateCodeData);
    setModalComplete(true);
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    setModalComplete(false);
  };

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.zip';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const generateDownloadUrl = (crdDate: string, distCd: string) => {
    setUploadLoader(true);
    setDateData(crdDate);
    // setDistCode(stateCd);
    setDistCode(distCd);
    dispatch(genPrintUrlReq({
      requestType: APIHeader.REQ_GEN_PRINT_URL,
      userType: UserType.PRNT,
      token: tokenData,
      dist: distCd,
      printId: crdDate,
    }));
  };

  React.useEffect(() => {
    if (genPrintUrlResponse.isError && genPrintUrlResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(genPrintUrlResponse.message);
      setErrorShowAlert(true);
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  React.useEffect(() => {
    if (!genPrintUrlResponse.isError && genPrintUrlResponse.message === 'executed') {
      setUploadLoader(false);
      if (genPrintUrlResponse.url !== '') {
        Promise.resolve(
          handleDownload(genPrintUrlResponse.url),
        ).then(() => {
          dispatch(clearGenPrintUrlReq());
        }).then(() => {
          dispatch(getCardMassStatus({
            userType: UserType.PRNT,
            requestType: APIHeader.REQ_MASS_STS_UPDATE,
            stData: distCode,
            crdDt: dateData,
            crdSts: 'PRNTP',
            token: tokenData,
          }));
          setUploadLoader(false);
          window.location.reload();
        });
      } else {
        setAlertAPIMsg('Print ready Zip URL is not created yet. Create using Download link');
        setErrorShowAlert(true);
        dispatch(clearGenPrintUrlReq());
      }
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles: File[] = Array.from(event.target.files || []);
    const newFileItems: ArrayFileData[] = newFiles.map((file, index) => ({
      id: index,
      file,
      status: false,
      loc: '',
    }));

    const processedFiles = new Set<string>();

    const sanitizedFiles = newFileItems.map((fileItem) => {
      const fileNameParts = fileItem.file.name.split('.');
      const fileExtension = fileNameParts.pop();
      // const sanitizedFileName = fileNameParts.join('').replace(/[.\s\W]+/g, '').toLowerCase();
      const uniqueSanitizedFullFileName = `${distCode}_${dateData}.${fileExtension}`;

      while (processedFiles.has(uniqueSanitizedFullFileName)) {
        // Append a unique identifier or handle the situation as per your requirement
      }

      processedFiles.add(uniqueSanitizedFullFileName);

      const newFile = new File([fileItem.file], uniqueSanitizedFullFileName, { type: fileItem.file.type });

      return {
        ...fileItem,
        file: newFile,
      };
    });

    // Assuming setSelectedFiles is a function to set the state for an array of files
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...sanitizedFiles]);

    const objectUrl = URL.createObjectURL(newFiles[0]);
    setFilePreview(objectUrl);
  };

  const handleUpload = () => {
    setUploadLoader(true);
    Promise.resolve(
      dispatch(getAWSFilesUpload({
        bucketNme: AWS_CARD_ACTIVITY_BUCKET,
        selectedFiles,
        requestType: '',
        isZip: false,
      })),
    )
      .then(() => {
        dispatch(clearAWSFilesUpload());
      });
  };

  React.useEffect(() => {
    if (uploadFilesResponse.message === 'executed' && userTp === 'PRNT') {
      setUploadLoader(true);
      dispatch(cardStatusReq({
        requestType: APIHeader.REQ_PRINT_STATUS_DATA,
        userType: UserType.PRNT,
        crdDt: dateData,
        token: tokenData,
        distCd: distCode,
        cSts: 'PRNTC',
      }));
    }
  }, [uploadFilesResponse.message, uploadFilesResponse.success, uploadFilesResponse.failed]);

  React.useEffect(() => {
    if (generatePrintZipResponse.error && generatePrintZipResponse.message !== '') {
      setUploadLoader(false);
      setModalComplete(false);
      setAlertAPIMsg(generatePrintZipResponse.message);
      setErrorShowAlert(true);
    }
  }, [generatePrintZipResponse.error, generatePrintZipResponse.message]);

  React.useEffect(() => {
    if (!generatePrintZipResponse.error && generatePrintZipResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(generatePrintZipResponse.message);
      setSuccessShowAlert(true);
      dispatch(clearCardStatusReq());
    }
  }, [generatePrintZipResponse.error, generatePrintZipResponse.message]);

  return (
    <div className="align-data-print-mobile mr">
      {uploadLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mx-1 align-view-to-row pt-2">
        <h6 className="input-header-text">Printable File</h6>
      </div>
      <Row>
        <Col lg={{ size: 12, order: 1 }} xs={{ order: 2 }}>
          <div className="pt-3 table-responsive">
            {printerData && printerData.prntData.length === 0 ? (
              <div className="pt-5 align-center">
                <span className="form-collapse-card-header">No Record Found</span>
              </div>
            )
              : (
                <div className="pre-print-table-scroll-y">
                  <table className="styled-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>District</th>
                        <th>Talukas/Town Panchayat</th>
                        <th>Total Cards</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {printerData && printerData.prntData.map((data, index) => (
                        <tr key={`file-${index.toString()}`}>
                          <td data-name="filename" className="s3Cnt1">
                            {data.PrntId}
                          </td>
                          <td className="folderName1">
                            {data.distNme}({data.stCd})
                          </td>
                          <td className="folderName1">
                            <FontAwesomeIcon icon={faFolder} className="info-stadm-icon mx-1" />
                            {data.PrntTp}
                          </td>
                          <td className="s3Cnt">
                            {data.cnt}
                          </td>
                          <td className="align-center1">
                            <Button disabled={data.cSts === 'PRNTP' || data.cSts === 'PRNTC'} id={`downloadZip-${(index + 1).toString()}`} className="button-icon" onClick={() => generateDownloadUrl(data.PrntId, data.PrntTp)}>
                              <FontAwesomeIcon icon={faDownload} className="mx-1 button-icon-small" />
                            </Button>
                            <UncontrolledTooltip delay={0} target={`downloadZip-${(index + 1).toString()}`}>
                              Download Zip
                            </UncontrolledTooltip>
                            {data.cSts === 'PRNTP' ? (
                              <Button className="acps-button-link" onClick={() => handleShowModelComplete(data.PrntId, data.PrntTp, data.distNme)}>
                                <span className="do-you-know-header-text">Mark Complete</span>
                              </Button>
                            ) : data.cSts === 'PRNTC' ? <span className="print-completed">Print Completed</span> : null}
                            <Modal isOpen={modalComplete}>
                              <ModalBody>
                                <span className="text-lite-grey">Upload the Courier Reciept for: <br />Taluka/Town Panchayat - {distCode}, {distName} <br />Date - {dateData}</span>
                                <div className="print-sadmin-kb-file-upload pt-2 mx-1 my-3">
                                  <div className="print-sadmin-file-upload-box">
                                    <Input
                                      accept=".png, .jpg, .jpeg"
                                      type="file"
                                      id="courIdImage"
                                      className="print-sadmin-file-upload-input"
                                      onChange={handleFileChange}
                                      multiple
                                      key={selectedFiles.length}
                                    />
                                    <span>Drag and drop or <span className="dcord-file-link">Upload Courier reciept</span></span>
                                  </div>
                                </div>
                                {selectedFiles.length > 0
                                  ? (
                                    <div className="align-center pt-2">
                                      <CardImg alt="logo" className="img-fluid avatar-user-profile-preview" src={filePreview} />
                                    </div>
                                  ) : null }
                                <div className="align-end pt-3">
                                  <Button className="acps-button-oval mx-1" onClick={() => handleCancel()}>
                                    Cancel
                                  </Button>
                                  <Button disabled={selectedFiles.length === 0} className="acps-button-oval mx-1" onClick={() => handleUpload()}>
                                    Submit
                                  </Button>
                                </div>
                              </ModalBody>
                            </Modal>
                          </td>
                        </tr>
                      ))}
                      {/* Once Zip downloaded disable the download field and open the Failed count field + Save Button */}
                    </tbody>
                  </table>
                </div>
                // <div className="pre-print-table-scroll-y">
                //   <table className="styled-table">
                //     <thead>
                //       <tr>
                //         <th>Date</th>
                //         <th>District</th>
                //         <th>File Name</th>
                //         <th>Total Cards</th>
                //         <th>Action</th>
                //       </tr>
                //     </thead>
                //     <tbody>
                //       {printerData && printerData.prntData.map((data, index) => (
                //         <tr key={`file-${index.toString()}`}>
                //           <td data-name="filename" className="s3Cnt1">
                //             {data.PrntId}
                //           </td>
                //           <td className="folderName1">
                //             {data.distNme}({data.stCd})
                //           </td>
                //           <td className="folderName1">
                //             <FontAwesomeIcon icon={faFolder} className="fa-file-alt mx-1" />
                //             {data.PrntTp}
                //           </td>
                //           <td className="s3Cnt">
                //             {data.cnt}
                //           </td>
                //           <td className="align-center1">
                //             <Button disabled={data.cSts === 'PRNTP' || data.cSts === 'PRNTC' || data.cnt === '0'} id={`downloadZip-${(index + 1).toString()}`} className="button-icon" onClick={() => generateDownloadUrl(data.PrntId, data.PrntTp)}>
                //               <FontAwesomeIcon icon={faCloudDownload} className="mx-1 button-icon-small" />
                //             </Button>
                //             <UncontrolledTooltip delay={0} target={`downloadZip-${(index + 1).toString()}`}>
                //               Download Zip
                //             </UncontrolledTooltip>
                //             {data.cSts === 'PRNTP' ? (
                //               <Button className="acps-button-link" onClick={() => handleShowModelComplete(data.PrntId, data.PrntTp, data.distNme)}>
                //                 <span className="do-you-know-header-text">Mark Complete</span>
                //               </Button>
                //             ) : data.cSts === 'PRNTC' ? <span className="print-completed">Print Completed</span> : null}
                //             <Modal isOpen={modalComplete}>
                //               <ModalBody>
                //                 <span className="text-lite-grey">Upload the Courier Reciept for: <br />District - {distName} <br />Date - {dateData}</span>
                //                 <div className="print-sadmin-kb-file-upload pt-2 mx-1 my-3">
                //                   <div className="print-sadmin-file-upload-box">
                //                     <Input
                //                       accept=".png, .jpg, .jpeg"
                //                       type="file"
                //                       id="courIdImage"
                //                       className="print-sadmin-file-upload-input"
                //                       onChange={handleFileChange}
                //                       multiple
                //                       key={selectedFiles.length}
                //                     />
                //                     <span>Drag and drop or <span className="dcord-file-link">Upload Courier reciept</span></span>
                //                   </div>
                //                 </div>
                //                 {selectedFiles.length > 0
                //                   ? (
                //                     <div className="align-center pt-2">
                //                       <CardImg alt="logo" className="img-fluid avatar-user-profile-preview" src={filePreview} />
                //                     </div>
                //                   ) : null }
                //                 <div className="align-end pt-3">
                //                   <Button className="acps-button-oval mx-1" onClick={() => handleCancel()}>
                //                     Cancel
                //                   </Button>
                //                   <Button disabled={selectedFiles.length === 0} className="acps-button-oval mx-1" onClick={() => handleUpload()}>
                //                     Submit
                //                   </Button>
                //                 </div>
                //               </ModalBody>
                //             </Modal>
                //           </td>
                //         </tr>
                //       ))}
                //       {/* Once Zip downloaded disable the download field and open the Failed count field + Save Button */}
                //     </tbody>
                //   </table>
                // </div>
              ) }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PrinterGeneratePrintFile;
