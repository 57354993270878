import { BillingResponse, BillingResults } from './billing.service.types';

export const BillingTransform: (response: BillingResponse) => BillingResults = (billingResults) => {
  const { data, is_error, message } = billingResults;
  const result: BillingResults = {
    data: [
      {
        PrntId: '',
        stData: {
          distNme: '',
          stCd: '',
          stNme: '',
        },
        PrntTp: '',
        costPrint: '',
        stsClient: '',
        stsVendor1: '',
        stsPrnt: '',
        gstVendor1: '0',
        stsZameer: '',
        cSts: '',
        updtOn: '',
        prntUsrCd: {
          st: '',
          ph: '',
          UserCd: '',
          lNme: '',
          fNme: '',
          eId: '',
        },
        updtCnt: 0,
        costVendor1: '0',
        cnt: '0',
        costCrd: '0',
        gstCrd: '0',
        gstPrint: '0',
        cardCost: '0',
        gstCardCost: '0',
        tCrdCost: '',
        tPrntCost: '',
        tVendor1Cost: '',
        costWDS: '0',
        gstWDS: '0',
        tWDSCost: '',
        costZameer: '0',
        gstZameer: '0',
        tZameerCost: '',
        tWDSProf: '',
        gstWDSProf: '',
        costWDSProf: '',
        courURL: '',
        balAmnt: '',
        miscCst: '',
        courTp: '',
        courTpVal: '',
        courId: '',
      },
    ],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};
