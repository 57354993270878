import { ListSubFolCountResponse, ListSubFolCountResults } from './list-subFol-count.service.types';

export const ListSubFolCountTransform: (response: ListSubFolCountResponse) => ListSubFolCountResults = (listSubFolCntResults) => {
  const { data, is_error, message } = listSubFolCntResults;
  const result: ListSubFolCountResults = {
    prntDet: [{
      prntUsrCd: {
        ph: '',
        UserCd: '',
        lNme: '',
        fNme: '',
        eId: '',
      },
      updtOn: '',
      costCrd: '',
      costPrint: '',
      costVendor1: '',
      gstCrd: '',
      gstPrint: '',
      gstVendor1: '',
      cSts: '',
      cnt: '',
      courId: '',
      tk: '',
      dist: {
        distNme: '',
        stCd: '',
        stNme: '',
      },
      PrntTp: '',
      updtBy: '',
      PrntId: '',
      courTp: '',
      miscCst: '',
      miscPrnt: '0',
      miscVndr1: '0',
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.prntDet = data;
    result.message = 'executed';
  }
  return result;
};
