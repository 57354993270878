import { PrePrintZipResponse, PrePrintZipResults } from './get-pre-print.service.types';

export const PrePrintZipTransform: (response: PrePrintZipResponse) => PrePrintZipResults = (prePrintResults) => {
  const { is_error, message, data } = prePrintResults;
  const result: PrePrintZipResults = {
    message: '',
    error: false,
    id: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
    result.id = data.id;
  }
  return result;
};
