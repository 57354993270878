import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
// import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import './sadmin.scss';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import Select from 'react-select';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { successAlert, errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import {
  clearGetTxnReport,
  clearSuperAdminDashboard, clearSuperAdminTCount, clearSuperAdminTrendSts, getSuperAdminDashboard, getSuperAdminTCount, getSuperAdminTrendSts, getTxnReport,
} from '../../../store/yoCard/actions';
import { APIHeader, AdminCurrentStatusList } from '../../../utils/constants';
import { StatusCountData } from '../../../services/reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.types';
import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';
import SAdminTable from './sadmin-card-table';
import { TxnsReportResult } from '../../../services/sadmin/accounts/txn-report/get-txn-report.types';
import { SATCountResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-tcount/superadmin-tcount.types';

export const SuperAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  // const history = useNavigate();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [loaderAdmin, setLoaderAdmin] = useState(false);
  const superAdminResponse = useSelector((state: RootState) => state.yoCard.superAdminDashboard);
  const superAdminTrendStsResponse = useSelector((state: RootState) => state.yoCard.superAdminTrendSts);
  const superAdminTCountResponse = useSelector((state: RootState) => state.yoCard.superAdminTCount);
  const [currentStatus, setCurrentStatus] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [inputDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const order: (keyof StatusCountData)[] = ['progCnt', 'compCnt'];
  const txnReportResponse = useSelector((state: RootState) => state.yoCard.getTxnReport);
  const [dataArrayDough, setDataArrayDough] = useState<number[]>([]);
  const [trendStatus, setTrendStatus] = useState<SATrendResult[]>([{
    PrntId: '',
    PrntTp: '',
    cnt: '',
    cSts: '',
    updtOn: '',
    stData: {
      distNme: '',
      stCd: '',
      stNme: '',
    },
  }]);

  const [tCardCount, setTCardCount] = useState<SATCountResult>({
    tCardCnt: '',
    tCardCst: '',
    tCardGST: '',
  });

  const dataDough = {
    labels: ['In-Progress', 'Couriered'],
    datasets: [
      {
        data: dataArrayDough,
        backgroundColor: ['#9366d2', '#11404D'],
        hoverBackgroundColor: ['#9366d2', '#11404D'],
      },
    ],
  };
  const optionsDough = {
    plugins: {
      color: '#FAFAFA',
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (context: any[]) => {
            if (context.length > 0) {
              const { dataIndex } = context[0];
              return dataDough.labels[dataIndex];
            }
            return '';
          },
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value}`;
          },
        },
      },
    },
  };
  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getSuperAdminDashboard({
          userType: userTp,
          requestType: APIHeader.REQ_SUPERADMIN_DASHBOARD,
          crdDt: inputDate,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearSuperAdminDashboard());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (superAdminResponse.isError && superAdminResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(superAdminResponse.message);
      dispatch(clearSuperAdminDashboard());
    }
  }, [superAdminResponse.isError, superAdminResponse.message]);

  React.useEffect(() => {
    if (!superAdminResponse.isError && superAdminResponse.message === 'executed') {
      setLoaderAdmin(false);
      setDataArrayDough(order.map((property) => superAdminResponse.data.crntSts[property]));
      dispatch(clearSuperAdminDashboard());
    }
  }, [superAdminResponse.isError, superAdminResponse.message]);

  const handleStatusSearch = () => {
    setLoaderAdmin(true);
    Promise.resolve(
      dispatch(getSuperAdminTrendSts({
        userType: userTp,
        requestType: APIHeader.REQ_SUPERADMIN_TREND_STS,
        sDt: startDate,
        eDt: endDate,
        filtrTp: 'STATUS',
        filtrSts: currentStatus,
        token: tokenData,
      })),
    ).then(() => {
      dispatch(clearSuperAdminTrendSts());
    });
  };

  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getSuperAdminTrendSts({
          userType: userTp,
          requestType: APIHeader.REQ_SUPERADMIN_TREND_STS,
          sDt: '',
          eDt: '',
          filtrTp: 'ALL',
          filtrSts: '',
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearSuperAdminTrendSts());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (superAdminTrendStsResponse.isError && superAdminTrendStsResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(superAdminTrendStsResponse.message);
      dispatch(clearSuperAdminTrendSts());
    }
  }, [superAdminTrendStsResponse.isError, superAdminTrendStsResponse.message]);

  React.useEffect(() => {
    if (!superAdminTrendStsResponse.isError && superAdminTrendStsResponse.message === 'executed') {
      setLoaderAdmin(false);
      setTrendStatus(superAdminTrendStsResponse.data);
      dispatch(clearSuperAdminTrendSts());
    }
  }, [superAdminTrendStsResponse.isError, superAdminTrendStsResponse.message]);

  const handleTotalCardCount = () => {
    setLoaderAdmin(true);
    Promise.resolve(
      dispatch(getSuperAdminTCount({
        userType: userTp,
        requestType: APIHeader.REQ_TOTAL_COUNT_ALL_INVOICE,
        sDt: '',
        eDt: '',
        filtrTp: 'TCNT',
        token: tokenData,
      })),
    ).then(() => {
      dispatch(clearSuperAdminTCount());
    });
  };

  React.useEffect(() => {
    if (superAdminTCountResponse.isError && superAdminTCountResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(superAdminTCountResponse.message);
      dispatch(clearSuperAdminTCount());
    }
  }, [superAdminTCountResponse.isError, superAdminTCountResponse.message]);

  React.useEffect(() => {
    if (!superAdminTCountResponse.isError && superAdminTCountResponse.message === 'executed') {
      setLoaderAdmin(false);
      setTCardCount(superAdminTCountResponse.data);
      dispatch(clearSuperAdminTCount());
    }
  }, [superAdminTCountResponse.isError, superAdminTCountResponse.message]);

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const currentStatusoptions = AdminCurrentStatusList.map((cStatus) => ({
    label: cStatus.label,
    value: cStatus.value,
  }));

  const handleSelectCurrentStatus = (e: any) => {
    if (e) {
      setCurrentStatus(e.value);
    } else {
      setCurrentStatus('');
    }
  };

  const selectedCStatusOptions = currentStatusoptions.find(
    (option) => option.value === currentStatus,
  );

  const [dataArrayAccountDough, setDataArrayAccountDough] = useState<number[]>([]);
  const orderAcc: (keyof TxnsReportResult)[] = ['advance', 'client', 'printer', 'vendor1', 'rzEnt'];
  const dataAccountDough = {
    labels: ['Advance', 'Utilization', 'Printer', 'Vendor#1', 'RZ Ent.'],
    datasets: [
      {
        data: dataArrayAccountDough,
        backgroundColor: ['#1D8CF8', '#F05E25', '#fece00', '#4b3f72', '#E14ECA'],
        hoverBackgroundColor: ['#1D8CF8', '#F05E25', '#fece00', '#4b3f72', '#E14ECA'],
      },
    ],
  };
  const optionsAccountDough = {
    plugins: {
      color: '#FAFAFA',
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        titleFont: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: (context: any[]) => {
            if (context.length > 0) {
              const { dataIndex } = context[0];
              return dataAccountDough.labels[dataIndex];
            }
            return '';
          },
          label: (context: any) => {
            const label = context.dataset.label || '';
            const value = context.parsed || 0;
            return `${label}: ${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')} (Rs/-)`;
          },
        },
      },
    },
  };

  React.useEffect(() => {
    if (userTp === 'SADM') {
      setLoaderAdmin(true);
      Promise.resolve(
        dispatch(getTxnReport({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_TXN_REPORT,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearGetTxnReport());
      });
    }
  }, [userTp]);

  React.useEffect(() => {
    if (txnReportResponse.isError && txnReportResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertMsg(txnReportResponse.message);
      dispatch(clearGetTxnReport());
    }
  }, [txnReportResponse.isError, txnReportResponse.message]);

  React.useEffect(() => {
    if (!txnReportResponse.isError && txnReportResponse.message === 'executed') {
      setLoaderAdmin(false);
      setDataArrayAccountDough(orderAcc.map((property) => txnReportResponse.data[property]));
      dispatch(clearGetTxnReport());
    }
  }, [txnReportResponse.isError, txnReportResponse.message]);

  return (
    <div className="border-tabs mr">
      { loaderAdmin ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2 pt-1">
        <span className="acps-header-with-bg">
          Quick View - Dashboard - New
        </span>
      </div>
      <Row className="pt-1 align-center">
        {/* <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">Card Status & Billing Details</span>
        </div> */}
        <Col xs="12" lg="4">
          <div className="form-card-border1 pt-2">
            <div className="my-4 align-center ">
              <Doughnut data={dataDough} options={optionsDough} />
            </div>
            <div className="my-4 align-center ">
              <span>Card Status</span>
            </div>
          </div>
        </Col>
        <Col xs="12" lg="3">
          <Row>
            <Col xs="12">
              <div className="align-center pt-4">
                <span className="tcount-title">Billing Info Date wise and CourierInfo</span>
              </div>
            </Col>
            <Col xs="12">
              <div className="align-center pt-3">
                {tCardCount.tCardCnt !== '' ? <span className="tcount-number my-2">{tCardCount.tCardCnt.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</span>
                  : <span className="tcount-subtitle my-5">Check Total Card Count</span>}
              </div>
              <div className="align-center">
                <Button className="button-icon mx-1" onClick={() => handleTotalCardCount()}>
                  <FontAwesomeIcon className="refresh-icon mx-1" icon={faRefresh} />
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="4">
          <div className="pt-2">
            <a href="/sadmin/accounts" rel="noopener noreferrer">
              <Doughnut data={dataAccountDough} options={optionsAccountDough} />
            </a>
          </div>
          <div className="my-4 align-center ">
            <span>Account Summary</span>
          </div>
          {/* <div className="pt-2">
            <div className="my-4 align-center ">
              <Button className="button-icon" onClick={() => { history('/sadmin/accounts'); window.scrollTo(0, 0); }}>
                <Doughnut data={dataAccountDough} options={optionsAccountDough} />
              </Button>
            </div>
            <div className="my-4 align-center ">
              <span>Account Summary</span>
            </div>
          </div> */}
        </Col>
      </Row>
      <Row className="pt-5 mx-2  align-center">
        <div
          style={{
            backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
          }}
          className="my-3 align-center"
        >
          <span className="mx-2">Village wise status</span>
        </div>
        <Col lg="12">
          <Row className="align-start">
            <Col lg="3">
              <div className="pt-1">
                <h6 className="input-header-text">Start Date</h6>
                <Input
                  value={startDate}
                  type="date"
                  name="refDt"
                  onChange={handleStartDate}
                  className="acps-input-ref"
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="pt-1">
                <h6 className="input-header-text">End Date</h6>
                <Input
                  max={today}
                  value={endDate}
                  type="date"
                  name="refDt"
                  onChange={handleEndDate}
                  className="acps-input-ref"
                />
              </div>
            </Col>
            <Col lg="5">
              <div className="pt-1">
                <h6 className="input-header-text">Select Status</h6>
                <Select
                  value={selectedCStatusOptions}
                  options={currentStatusoptions}
                  isClearable
                  onChange={handleSelectCurrentStatus}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (base) => ({
                      ...base,
                      // position: 'absolute',
                      // overflow: 'visible',
                    }),
                    control: (base: any) => ({
                      ...base,
                      '&:hover': { borderColor: '#880000' },
                      border: '1px solid #DDE0E4',
                      width: '100%',
                      borderRadius: '5px',
                      fontSize: '15px',
                      fontWeight: 400,
                      color: '#575656',
                      boxShadow: 'none',
                      backgroundColor: '#fff',
                      '&:focus': {
                        borderColor: '#E1EDF8',
                        boxShadow: 'none',
                        color: '#575656',
                        background: '#fff',
                      },
                    }),
                    option: (provided: any, state: { isSelected: any; }) => ({
                      ...provided,
                      color: state.isSelected ? '#383838' : '#212121',
                      padding: 10,
                      textAlign: 'left',
                      background: state.isSelected ? '#FAFCFB' : '#fff',
                      '&:hover': {
                        background: '#FAFCFB',
                      },
                    }),
                  }}
                />
              </div>
            </Col>
            <Col lg="1">
              <div className="pt-4 align-end">
                <div className="pt-1">
                  <Button className="acps-button-oval mx-1" onClick={() => handleStatusSearch()}>
                    Search
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          {trendStatus.length === 0 ? (
            <div className="pt-5 align-center">
              <span className="form-collapse-card-header">No Record Found</span>
            </div>
          )
            : (
              <SAdminTable data={trendStatus} />) }
        </Col>
      </Row>
      <div className="mb-3" />
    </div>
  );
};

export default SuperAdminDashboard;
