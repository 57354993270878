import React from 'react';
import { RootState } from 'typesafe-actions';
import * as XLSX from 'xlsx';
import {
  Button, Col, Input, Modal, ModalBody, ModalHeader, Row, Tooltip, UncontrolledTooltip,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import '../sadmin.scss';
import Select from 'react-select';
import { faCircleInfo, faDownload, faHouseChimneyUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { SubFolCountResultRes } from '../../../../services/get-s3-subFol-count/list-subFol-count.types';
import { clearGenPrintUrlReq, genPrintUrlReq, postPrintStatusReq } from '../../../../store/yoCard/actions';
import { getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import LoaderData from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { APIHeader, ConsgTpList, UserType } from '../../../../utils/constants';
import { CardBillingInput, VillageCountBlockWise } from '../../../../services/update-print-status/update-print-status.types';

interface TableProps {
  data: SubFolCountResultRes[];
}

const PrintReadyTable: React.FC<TableProps> = ({ data }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = React.useState('');
  const [taluka, setTaluka] = React.useState<string>('');
  const [cardDt, setCardDt] = React.useState<string>('');
  const [modalComplete, setModalComplete] = React.useState(false);
  const [uploadLoader, setUploadLoader] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [xlsxInput, setXlsxInput] = React.useState<VillageCountBlockWise[]>([]);
  const [tooltipOpen, setTooltipOpen] = React.useState<Record<string, boolean>>({});
  const [openModalPrint, setOpenModalPrint] = React.useState<Record<string, boolean>>({});
  const updatePrintStatusResponse = useSelector((state: RootState) => state.yoCard.postPrintStatus);
  const genPrintUrlResponse = useSelector((state: RootState) => state.yoCard.genPrintUrl);
  const [billingInput, setBillingInput] = React.useState<CardBillingInput>({
    cnt: '',
    courId: 'NA',
    costCrd: '0',
    gstCrd: '0',
    costPrint: '0',
    gstPrint: '0',
    costVendor1: '0',
    gstVendor1: '0',
    stsClient: 'BPFO',
    stsPrnt: 'PPFP',
    stsVendor1: 'PPFV1',
    stsZameer: 'PPFZ',
    courTp: '',
    miscCst: '',
    miscPrnt: '0',
    miscVndr1: '0',
  });
  console.log('Check msisc', billingInput);
  const handleFieldChangeBilling = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingInput({ ...billingInput, [e.target.name]: e.target.value });
  };

  const printToggleModal = (target: string, rowData: SubFolCountResultRes) => {
    setOpenModalPrint((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setBillingInput({
      ...billingInput,
      cnt: rowData.cnt ? rowData.cnt.toString() : '0',
      courId: rowData.courId ? rowData.courId : '',
      costCrd: rowData.costCrd ? rowData.costCrd : '0',
      gstCrd: rowData.gstCrd ? rowData.gstCrd : '0',
      costPrint: rowData.costPrint ? rowData.costPrint : '0',
      gstPrint: rowData.gstPrint ? rowData.gstPrint : '0',
      costVendor1: rowData.costVendor1 ? rowData.costVendor1 : '0',
      gstVendor1: rowData.gstVendor1 ? rowData.gstVendor1 : '0',
      miscCst: rowData.miscCst ? rowData.miscCst : '0',
      miscPrnt: rowData.miscPrnt ? rowData.miscPrnt : '0',
      miscVndr1: rowData.miscVndr1 ? rowData.miscVndr1 : '0',
      courTp: rowData.courTp ? rowData.courTp : 'UNK',
    });
  };

  const toggle = (target: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  if (!data || data.length === 0) {
    return null;
  }
  const updatePrintData = (target: string, printType: string, printId: string) => {
    setUploadLoader(true);
    dispatch(postPrintStatusReq({
      requestType: APIHeader.REQ_UPDATE_CARD_BILL,
      userType: UserType.SADM,
      village: printType,
      crdDt: printId,
      crdSts: '',
      billingInfo: billingInput,
      token: tokenData,
    }));
    toggle(target);
  };

  React.useEffect(() => {
    if (updatePrintStatusResponse.error && updatePrintStatusResponse.errorMessage !== '') {
      setUploadLoader(false);
      if (updatePrintStatusResponse.errorMessage === 'Failed') {
        setAlertAPIMsg('You have already updated printing details for the provided date and village. For additional assistance, please contact the administrator.');
      } else {
        setAlertAPIMsg(updatePrintStatusResponse.errorMessage);
      }
      setErrorShowAlert(true);
    }
  }, [updatePrintStatusResponse.error, updatePrintStatusResponse.errorMessage]);

  React.useEffect(() => {
    if (!updatePrintStatusResponse.error && updatePrintStatusResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(updatePrintStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [updatePrintStatusResponse.error, updatePrintStatusResponse.message]);

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.zip';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const generateDownloadUrl = (distCd: string, crdDate: string) => {
    setUploadLoader(true);
    dispatch(genPrintUrlReq({
      requestType: APIHeader.REQ_GEN_PRINT_URL,
      userType: UserType.SADM,
      token: tokenData,
      dist: distCd,
      printId: crdDate,
    }));
  };

  React.useEffect(() => {
    if (genPrintUrlResponse.isError && genPrintUrlResponse.message !== '') {
      setUploadLoader(false);
      setAlertAPIMsg(genPrintUrlResponse.message);
      setErrorShowAlert(true);
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  React.useEffect(() => {
    if (!genPrintUrlResponse.isError && genPrintUrlResponse.message === 'executed') {
      setUploadLoader(false);
      if (genPrintUrlResponse.url !== '') {
        Promise.resolve(
          handleDownload(genPrintUrlResponse.url),
        ).then(() => {
          dispatch(clearGenPrintUrlReq());
        });
      } else {
        setAlertAPIMsg('Print ready Zip URL is not created yet. Create using Download link');
        setErrorShowAlert(true);
        dispatch(clearGenPrintUrlReq());
      }
    }
  }, [genPrintUrlResponse.isError, genPrintUrlResponse.message]);

  const handleShowModelVillageCnt = (talukaData: string, crdDate: string) => {
    setTaluka(talukaData);
    setCardDt(crdDate);
    setModalComplete(true);
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    setXlsxInput([]);
    setModalComplete(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      setSelectedFiles([...files]);
    }
  };

  const processExcelFile = (blockName: string, file: File): VillageCountBlockWise[] => {
    const reader = new FileReader();
    console.log('ChblockName', blockName);
    reader.onload = (event) => {
      const data_sheet = event.target?.result as string;
      const workbook = XLSX.read(data_sheet, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      const result: VillageCountBlockWise[] = [];

      excelData.forEach((row: any) => {
        const block = row.Block.replace(/\s/g, '').toLowerCase();
        // const villageTown = row['Village-Town']..replace(/\s/g, '').toLowerCase();
        const normalizedBlockName = blockName.replace(/\s/g, '').toLowerCase();

        // Use a helper function to convert a string to title case
        const toTitleCase = (str: string) => str.replace(/\b\w/g, (char) => char.toUpperCase());

        // Normalize the block and blockName to lowercase and trim any spaces
        if (block === normalizedBlockName) {
          const villageId = `${toTitleCase(row['Village-Town'])}`.replace(/\s/g, '');
          const existingEntry = result.find((entry) => entry.villageId === villageId);

          if (existingEntry) {
            existingEntry.cnt = String(Number(existingEntry.cnt) + 1);
          } else {
            result.push({
              cnt: '1',
              villageId,
            });
          }
        }
      });

      console.log('1qaz excel count', result);
      setXlsxInput(result);
    };
    reader.readAsBinaryString(file);
    return xlsxInput;
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      // const blockName = 'ProvidedBlock'; // Replace with the desired block name
      const file = selectedFiles[0];
      processExcelFile(taluka, file);
    }
  };

  // Start Billing Card Type List

  const consgTpOptions = ConsgTpList.map((consg) => ({
    label: consg.label,
    value: consg.value,
  }));

  const handleSelectCourTypeData = (e: any) => {
    setBillingInput({ ...billingInput, courTp: e.value });
  };

  const selectedCourTypeOptions = consgTpOptions.find(
    (option) => option.value === billingInput.courTp,
  );
    // End Billing Card Type List

  return (
    <div>
      {uploadLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <table className="styled-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Village</th>
            <th>Status</th>
            <th>Card Count</th>
            <th>Printer</th>
            <th>Courier Id</th>
            <th>Last Updated</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length && data.map((row, rowIndex) => (
            <tr key={`file-${rowIndex.toString()}`}>
              <td>
                <span className="td-text">{row.PrntId}</span>
              </td>
              <td>
                <span className="td-text">{row.tk}</span><br />
                <span className="text-lite-grey">{row.dist.distNme}({row.dist.stCd})</span>
              </td>
              <td>
                <span className="td-text">{row.cSts}</span>
              </td>
              <td><span className="td-text">{row.cnt}</span></td>
              <td>
                <span className="td-text">
                  {row.prntUsrCd.fNme} {' '}{row.prntUsrCd.lNme}
                  <Button className="button-icon" id={`tooltip-${rowIndex}`}>
                    <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                  </Button>
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen[`tooltip-${rowIndex}`] || false}
                    target={`tooltip-${rowIndex}`}
                    toggle={() => toggle(`tooltip-${rowIndex}`)}
                  >
                    <h6>{row.prntUsrCd.fNme} {' '}{row.prntUsrCd.lNme}</h6>
                    <span>{row.prntUsrCd.eId}</span><br />
                    <span>{row.prntUsrCd.ph}</span><br />
                  </Tooltip>
                </span>
              </td>
              <td><span className="td-text">{row.courId}</span></td>
              <td><span className="collapse-header">{row.updtOn}</span></td>
              <td>
                <span className="align-center">
                  <Button id={`printDet-${(rowIndex + 1).toString()}`} className="button-icon mx-1" onClick={() => printToggleModal(`printDet-${rowIndex}`, row)}>
                    <FontAwesomeIcon className="collapse-icon mx-1" icon={faEdit} />
                  </Button>
                  <UncontrolledTooltip delay={0} target={`printDet-${(rowIndex + 1).toString()}`}>
                    Edit Costing, Courier & Count
                  </UncontrolledTooltip>
                  <Button id={`genUrl-${(rowIndex + 1).toString()}`} className="button-icon mx-1" onClick={() => generateDownloadUrl(row.PrntTp, row.PrntId)}>
                    <FontAwesomeIcon className="collapse-icon" icon={faDownload} />
                  </Button>
                  <UncontrolledTooltip delay={0} target={`genUrl-${(rowIndex + 1).toString()}`}>
                    Download Print ready Zip Files
                  </UncontrolledTooltip>
                  <Button disabled id={`villCnt-${(rowIndex + 1).toString()}`} className="button-icon mx-1" onClick={() => handleShowModelVillageCnt(row.PrntTp, row.PrntId)}>
                    <FontAwesomeIcon className="collapse-icon" icon={faHouseChimneyUser} />
                  </Button>
                  <UncontrolledTooltip delay={0} target={`villCnt-${(rowIndex + 1).toString()}`}>
                    Update Count per Village
                  </UncontrolledTooltip>
                </span>
                <Modal isOpen={modalComplete}>
                  <ModalBody>
                    <span className="text-lite-grey">Upload Processed .XLSX file to get all count for: <br />Taluka/Town Panchayat - {taluka} <br />Date - {cardDt}</span>
                    <div className="print-sadmin-kb-file-upload pt-2 mx-1 my-3">
                      <div className="print-sadmin-file-upload-box">
                        <Input
                          accept=".xlsx"
                          type="file"
                          id="courIdImage"
                          className="print-sadmin-file-upload-input"
                          onChange={handleFileChange}
                          multiple
                          key={selectedFiles.length}
                        />
                        <span>Drag and drop or <span className="dcord-file-link">Upload XLSX file to get Count</span></span>
                      </div>
                    </div>
                    {selectedFiles.length > 0
                      ? (
                        <div className="pt-2">
                          <table className="styled-table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Village ID</th>
                                <th>Count</th>
                              </tr>
                            </thead>
                            {xlsxInput.length === 0 ? (
                              <h6 className="form-collapse-card-header">No Record Found</h6>
                            )
                              : (
                                <tbody>
                                  {xlsxInput.map((entry, index) => (
                                    <tr key={`xlsx-${index.toString()}`}>
                                      <td>{index + 1}</td>
                                      <td>{entry.villageId}</td>
                                      <td>{entry.cnt}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              )}
                          </table>
                        </div>
                      ) : null }
                    <div className="align-end pt-3">
                      <Button className="acps-button-oval mx-1" onClick={() => handleCancel()}>
                        Cancel
                      </Button>
                      <Button disabled={selectedFiles.length === 0} className="acps-button-oval mx-1" onClick={() => handleUpload()}>
                        Preview
                      </Button>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal
                  isOpen={openModalPrint[`printDet-${rowIndex}`] || false}
                  target={`printDet-${rowIndex}`}
                  className="pt-5"
                >
                  <ModalHeader toggle={() => printToggleModal(`printDet-${rowIndex}`, row)} className="form-card-border-none mx-3 my-2" />
                  <div className="ml-1">
                    <span className="text-lite-grey">Date: {row.PrntId}, Village:{row.PrntTp}, {row.tk}, {row.dist.distNme}({row.dist.stCd})</span>
                  </div>
                  <ModalBody className="padding-zero mx-3">
                    <Row>
                      <Col lg="12">
                        <div className="pt-4">
                          <h6 className="decline-zip">Card Costing</h6>
                        </div>
                      </Col>
                      <Col lg="7">
                        <div className="pt-1">
                          <h6 className="input-header-text">Card Cost in Paise(1rs= 100P)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="costCrd"
                            required
                            value={billingInput.costCrd}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="5">
                        <div className="pt-1">
                          <h6 className="input-header-text">GST(%)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="gstCrd"
                            required
                            value={billingInput.gstCrd}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="pt-4">
                          <h6 className="decline-zip">Printing Costing<span className="mandate-star">*</span></h6>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-1">
                          <h6 className="input-header-text">Cost in Paise(1rs= 100P)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="costPrint"
                            required
                            value={billingInput.costPrint}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="pt-1">
                          <h6 className="input-header-text">GST(%)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="gstPrint"
                            required
                            value={billingInput.gstPrint}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="pt-1">
                          <h6 className="input-header-text">Misc(rs.)</h6>
                          <Input
                            type="number"
                            name="miscPrnt"
                            value={billingInput.miscPrnt}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="pt-4">
                          <h6 className="decline-zip">Vendor#1 Costing<span className="mandate-star">*</span></h6>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-1">
                          <h6 className="input-header-text">Cost in Paise(1rs= 100P)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="costVendor1"
                            required
                            value={billingInput.costVendor1}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="pt-1">
                          <h6 className="input-header-text">GST(%)<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="gstVendor1"
                            required
                            value={billingInput.gstVendor1}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="3">
                        <div className="pt-1">
                          <h6 className="input-header-text">Misc(rs.)</h6>
                          <Input
                            type="number"
                            name="miscVndr1"
                            value={billingInput.miscVndr1}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <div className="pt-4">
                          <h6 className="decline-zip">Card Count & Courier Details</h6>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-2">
                          <h6 className="input-header-text">Consignment</h6>
                          <Select
                            value={selectedCourTypeOptions}
                            options={consgTpOptions}
                            isClearable
                            onChange={handleSelectCourTypeData}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                                // position: 'absolute',
                                // overflow: 'visible',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#880000' },
                                border: '1px solid #DDE0E4',
                                width: '100%',
                                borderRadius: '5px',
                                fontSize: '15px',
                                fontWeight: 400,
                                color: '#575656',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 10,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-2">
                          <h6 className="input-header-text">Consignment No.#</h6>
                          <Input
                            type="text"
                            name="courId"
                            // disabled
                            value={billingInput.courId}
                            onChange={handleFieldChangeBilling}
                            className="acps-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-2">
                          <h6 className="input-header-text">Other Misc(rs.)</h6>
                          <Input
                            type="number"
                            name="miscCst"
                            value={billingInput.miscCst}
                            onChange={handleFieldChangeBilling}
                            className="yocard-input-ref"
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="pt-2">
                          <h6 className="input-header-text">Card Count<span className="mandate-star">*</span></h6>
                          <Input
                            type="number"
                            name="cnt"
                            required
                            value={billingInput.cnt}
                            onChange={handleFieldChangeBilling}
                            className="acps-input-ref"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="align-center my-1 pt-3">
                      <Button className="acps-button-oval" onClick={() => updatePrintData(`printDet-${rowIndex}`, row.PrntTp, row.PrntId)}>Submit</Button>
                    </div>
                  </ModalBody>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintReadyTable;
