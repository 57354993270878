import React, { useState } from 'react';
import './printer.scss';
import {
  Col, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { successAlert, errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { getTokenFromLocalStorage, getUserProfileSessionStorage, getUserTpSessionStorage } from '../../../utils/service/localstorage-service';
import {
  clearCardCountStateWise, clearPrinterDashboard, getPrinterDashboard,
} from '../../../store/yoCard/actions';
import { APIHeader } from '../../../utils/constants';
import PrinterGeneratePrintFile from './printer-generate-print-file';
import { PrinterDashboardData } from '../../../services/reports-all/get-printer-dashboard/printer-dashboard.types';

export const PrinterDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const userProfile = getUserProfileSessionStorage();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [userId, setUserId] = useState<string>('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg] = useState('');
  const [loaderProfile, setLoaderProfile] = useState(false);
  const getPrintDashboardResponse = useSelector((state: RootState) => state.yoCard.getPrinterDashboard);
  const [printerData, setPrinterData] = useState<PrinterDashboardData>({
    tCnt: 0,
    fCnt: 0,
    prntData: [{
      PrntTp: '',
      PrntId: '',
      cnt: '',
      distNme: '',
      cSts: '',
      stCd: '',
      stNme: '',
      tCnt: 0,
      fCnt: 0,
    }],
  });

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.UserCd) {
      setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
    }
  }, [userId, userProfile && JSON.parse(userProfile).profileData.UserCd]);

  React.useEffect(() => {
    if (userTp === 'PRNT' && userId !== '') {
      setLoaderProfile(true);
      Promise.resolve(
        dispatch(getPrinterDashboard({
          userType: userTp,
          requestType: APIHeader.REQ_REPORT_PRINT_DASHBOARD,
          printId: userId,
          token: tokenData,
        })),
      ).then(() => {
        dispatch(clearPrinterDashboard());
      });
    }
  }, [userTp, userId]);

  React.useEffect(() => {
    if (getPrintDashboardResponse.isError && getPrintDashboardResponse.message !== '') {
      setLoaderProfile(false);
      dispatch(clearPrinterDashboard());
    }
  }, [getPrintDashboardResponse.isError, getPrintDashboardResponse.message]);

  React.useEffect(() => {
    if (!getPrintDashboardResponse.isError && getPrintDashboardResponse.message === 'executed') {
      setPrinterData(getPrintDashboardResponse);
      setLoaderProfile(false);
      dispatch(clearCardCountStateWise());
    }
  }, [getPrintDashboardResponse.isError, getPrintDashboardResponse.message]);

  return (
    <div className="border-tabs mr">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2">
        <span className="acps-header-with-bg">
          Quick View - Dashboard
        </span>
      </div>
      <Row className="pt-3 align-center">
        <Col lg="11" xs="12">
          <PrinterGeneratePrintFile printerData={printerData} userTp={userTp} />
        </Col>
      </Row>
      <div className="mb-3" />
    </div>
  );
};

export default PrinterDashboard;
