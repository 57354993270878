import { PostRefFileRequest } from './add-new-ref-file/post-reffile.service';
import {
  PostRefFileService, PostRefFileOptions, PostRefFileResponse, PostRefFileResults,
} from './add-new-ref-file/post-reffile.service.types';
import { PostRefFileTransform } from './add-new-ref-file/post-reffile.transform';
import { PutPrintReadyRequest } from './add-print-ready/print-ready.service';
import {
  PutPrintReadyService, PutPrintReadyOptions, PutPrintReadyResponse, PutPrintReadyResults,
} from './add-print-ready/print-ready.service.types';
import { PutPrintReadyTransform } from './add-print-ready/print-ready.transform';
import { CheckRefFileRequest } from './check-ref-file/check-reffile.service';
import {
  CheckRefFileService, CheckRefFileOptions, CheckRefFileResponse, CheckRefFileResults,
} from './check-ref-file/check-reffile.service.types';
import { CheckRefFileTransform } from './check-ref-file/check-reffile.transform';
import { CheckUserRequest } from './check_user_exist/check-user.service';
import {
  CheckUserService, CheckUserOptions, CheckUserResponse, CheckUserResults,
} from './check_user_exist/check-user.service.types';
import { CheckUserTransform } from './check_user_exist/check-user.transform';
import { ContactUsPostRequest } from './contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './contact-us/contact-us.service.types';
import { ContactUsTransform } from './contact-us/contact-us.transform';
import { DelFileURLRequest } from './del-file-url/del-file-url.service';
import {
  DelFileURLOptions, DelFileURLResponse, DelFileURLResults, DelFileURLService,
} from './del-file-url/del-file-url.service.types';
import { DelFileURLTransform } from './del-file-url/del-file-url.transform';
import { ForgotPasswordRequest } from './forgot-password/forgot-password.service';
import {
  ForgotPasswordService, ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults,
} from './forgot-password/forgot-password.service.types';
import { ForgotPasswordTransform } from './forgot-password/forgot-password.transform';
import { GenTokenRequest } from './gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './gen-token/gen-token.service.types';
import { GenTokenTransform } from './gen-token/gen-token.transform';
import { GetAWSSubFolderRequest } from './get-aws-sub-folders/get-subfolders.service';
import {
  GetAWSSubFolderService, GetAWSSubFolderOptions, GetAWSSubFolderResponse, GetAWSSubFolderResults,
} from './get-aws-sub-folders/get-subfolders.service.types';
import { GetAWSSubFolderTransform } from './get-aws-sub-folders/get-subfolders.transform';
import { DownloadFileRequest } from './get-download-file-link/get-download-file.service';
import {
  DownloadFileOptions, DownloadFileResponse, DownloadFileResults, DownloadFileService,
} from './get-download-file-link/get-download-file.service.types';
import { DownloadFileTransform } from './get-download-file-link/get-download-file.transform';
import { FileURLRequest } from './get-file-url/get-file-url.service';
import {
  FileURLService, FileURLOptions, FileURLResponse, FileURLResults,
} from './get-file-url/get-file-url.service.types';
import { FileURLTransform } from './get-file-url/get-file-url.transform';
import { PrePrintZipRequest } from './get-pre-print-zip/get-pre-print.service';
import {
  PrePrintZipService, PrePrintZipOptions, PrePrintZipResponse, PrePrintZipResults,
} from './get-pre-print-zip/get-pre-print.service.types';
import { PrePrintZipTransform } from './get-pre-print-zip/get-pre-print.transform';
import { ProfileRequest } from './get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './get-profile/get-profile.service.types';
import { ProfileTransform } from './get-profile/get-profile.transform';
import { ListTalukaCountRequest } from './get-s3-loc-count/list-taluka-count.service';
import {
  ListTalukaCountService, ListTalukaCountOptions, ListTalukaCountResponse, ListTalukaCountResults,
} from './get-s3-loc-count/list-taluka-count.service.types';
import { ListTalukaCountTransform } from './get-s3-loc-count/list-taluka-count.transform';
import { ListSubFolCountRequest } from './get-s3-subFol-count/list-subFol-count.service';
import {
  ListSubFolCountOptions, ListSubFolCountResponse, ListSubFolCountResults, ListSubFolCountService,
} from './get-s3-subFol-count/list-subFol-count.service.types';
import { ListSubFolCountTransform } from './get-s3-subFol-count/list-subFol-count.transform';
import { ListCityRequest } from './meta-data/list-city-loc/list-city.service';
import {
  ListCityService, ListCityOptions, ListCityResponse, ListCityResults,
} from './meta-data/list-city-loc/list-city.service.types';
import { ListCityTransform } from './meta-data/list-city-loc/list-city.transform';
import { ListDistRequest } from './meta-data/list-district/list-dist.service';
import {
  ListDistService, ListDistOptions, ListDistResponse, ListDistResults,
} from './meta-data/list-district/list-dist.service.types';
import { ListDistTransform } from './meta-data/list-district/list-dist.transform';
import { ListPrinterIdRequest } from './meta-data/list-printer-id/list-printer-id.service';
import {
  ListPrinterIdService, ListPrinterIdOptions, ListPrinterIdResponse, ListPrinterIdResults,
} from './meta-data/list-printer-id/list-printer-id.service.types';
import { ListPrinterIdTransform } from './meta-data/list-printer-id/list-printer-id.transform';
import { ListStateRequest } from './meta-data/list-state/list-state.service';
import {
  ListStateService, ListStateOptions, ListStateResponse, ListStateResults,
} from './meta-data/list-state/list-state.service.types';
import { ListStateTransform } from './meta-data/list-state/list-state.transform';
import { ListTalukaAdmRequest } from './meta-data/list-taluka-adm/list-taluka-adm.service';
import {
  ListTalukaAdmService, ListTalukaAdmOptions, ListTalukaAdmResponse, ListTalukaAdmResults,
} from './meta-data/list-taluka-adm/list-taluka-adm.service.types';
import { ListTalukaAdmTransform } from './meta-data/list-taluka-adm/list-taluka-adm.transform';
import { ListTalukaRequest } from './meta-data/list-taluka/list-taluka.service';
import {
  ListTalukaService, ListTalukaOptions, ListTalukaResponse, ListTalukaResults,
} from './meta-data/list-taluka/list-taluka.service.types';
import { ListTalukaTransform } from './meta-data/list-taluka/list-taluka.transform';
import { ListRefFileRequest } from './ref-file-list/list-reffile.service';
import {
  ListRefFileService, ListRefFileOptions, ListRefFileResponse, ListRefFileResults,
} from './ref-file-list/list-reffile.service.types';
import { ListRefFileTransform } from './ref-file-list/list-reffile.transform';
import { RegisterPostRequest } from './register/register.service';
import {
  RegisterOptions, RegisterResponse, RegisterResults, RegisterService,
} from './register/register.service.types';
import { RegisterTransform } from './register/register.transform';
import { CardDashboardRequest } from './reports-all/get-cards-dashboard/card-dashboard.service';
import {
  CardDashboardService, CardDashboardOptions, CardDashboardResponse, CardDashboardResults,
} from './reports-all/get-cards-dashboard/card-dashboard.service.types';
import { CardDashboardTransform } from './reports-all/get-cards-dashboard/card-dashboard.transform';
import { CardsStateWiseRequest } from './reports-all/get-cards-state-wise/card-state-wise.service';
import {
  CardsStateWiseService, CardsStateWiseOptions, CardsStateWiseResponse, CardsStateWiseResults,
} from './reports-all/get-cards-state-wise/card-state-wise.service.types';
import { CardsStateWiseTransform } from './reports-all/get-cards-state-wise/card-state-wise.transform';
import { PrinterDashboardRequest } from './reports-all/get-printer-dashboard/printer-dashboard.service';
import {
  PrinterDashboardService, PrinterDashboardOptions, PrinterDashboardResponse, PrinterDashboardResults,
} from './reports-all/get-printer-dashboard/printer-dashboard.service.types';
import { PrinterDashboardTransform } from './reports-all/get-printer-dashboard/printer-dashboard.transform';
import { CardTrendStatusRequest } from './reports-all/get-trends-status/card-trend.service';
import {
  CardTrendStatusService, CardTrendStatusOptions, CardTrendStatusResponse, CardTrendStatusResults,
} from './reports-all/get-trends-status/card-trend.service.types';
import { CardTrendStatusTransform } from './reports-all/get-trends-status/card-trend.transform';
import { BillingRequest } from './reports-all/superadmin-dashboard/superadmin-billing/billing.service';
import {
  BillingService, BillingOptions, BillingResponse, BillingResults,
} from './reports-all/superadmin-dashboard/superadmin-billing/billing.service.types';
import { BillingTransform } from './reports-all/superadmin-dashboard/superadmin-billing/billing.transform';
import { SuperAdminDashboardRequest } from './reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.service';
import {
  SuperAdminDashboardService, SuperAdminDashboardOptions, SuperAdminDashboardResponse, SuperAdminDashboardResults,
} from './reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.service.types';
import { SuperAdminDashboardTransform } from './reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.transform';
import { EditBillingStatusRequest } from './reports-all/superadmin-dashboard/superadmin-edit-billing-status/update-billing-status.service';
import {
  EditBillingStatusService, EditBillingStatusOptions, EditBillingStatusResponse, EditBillingStatusResults,
} from './reports-all/superadmin-dashboard/superadmin-edit-billing-status/update-billing-status.service.types';
import { EditBillingStatusTransform } from './reports-all/superadmin-dashboard/superadmin-edit-billing-status/update-billing-status.transform';
import { SuperAdminTCountRequest } from './reports-all/superadmin-dashboard/superadmin-tcount/superadmin-tcount.service';
import {
  SuperAdminTCountService, SuperAdminTCountOptions, SuperAdminTCountResponse, SuperAdminTCountResults,
} from './reports-all/superadmin-dashboard/superadmin-tcount/superadmin-tcount.service.types';
import { SuperAdminTCountTransform } from './reports-all/superadmin-dashboard/superadmin-tcount/superadmin-tcount.transform';
import { SuperAdminTrendStsRequest } from './reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.service';
import {
  SuperAdminTrendStsService, SuperAdminTrendStsOptions, SuperAdminTrendStsResponse, SuperAdminTrendStsResults,
} from './reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.service.types';
import { SuperAdminTrendStsTransform } from './reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.transform';
import { RefStatusRequest } from './req-status-ref/ref-sts.service';
import {
  RefStatusService, RefStatusOptions, RefStatusResponse, RefStatusResults,
} from './req-status-ref/ref-sts.service.types';
import { RefStatusTransform } from './req-status-ref/ref-sts.transform';
import { PostTxnRequest } from './sadmin/accounts/add-transactions/post-txn.service';
import {
  PostTxnService, PostTxnOptions, PostTxnResponse, PostTxnResults,
} from './sadmin/accounts/add-transactions/post-txn.service.types';
import { PostTxnTransform } from './sadmin/accounts/add-transactions/post-txn.transform';
import { GetAllTxnRequest } from './sadmin/accounts/get-all-transactions/get-all-txn.service';
import {
  GetAllTxnService, GetAllTxnOptions, GetAllTxnResponse, GetAllTxnResults,
} from './sadmin/accounts/get-all-transactions/get-all-txn.service.types';
import { GetAllTxnTransform } from './sadmin/accounts/get-all-transactions/get-all-txn.transform';
import { GetTxnReportRequest } from './sadmin/accounts/txn-report/get-txn-report.service';
import {
  GetTxnReportService, GetTxnReportOptions, GetTxnReportResponse, GetTxnReportResults,
} from './sadmin/accounts/txn-report/get-txn-report.service.types';
import { GetTxnReportTransform } from './sadmin/accounts/txn-report/get-txn-report.transform';
import { UpdateTxnRequest } from './sadmin/accounts/update-transactions/update-txn.service';
import {
  UpdateTxnService, UpdateTxnOptions, UpdateTxnResponse, UpdateTxnResults,
} from './sadmin/accounts/update-transactions/update-txn.service.types';
import { UpdateTxnTransform } from './sadmin/accounts/update-transactions/update-txn.transform';
import { PrintLinkRequest } from './sadmin/admin/generate-print-link/generate-print-link.service';
import {
  PrintLinkService, PrintLinkOptions, PrintLinkResponse, PrintLinkResults,
} from './sadmin/admin/generate-print-link/generate-print-link.service.types';
import { PrintLinkTransform } from './sadmin/admin/generate-print-link/generate-print-link.transform';
import { CardStatusRequest } from './sadmin/admin/update-card-status/update-card-status.service';
import {
  CardStatusService, CardStatusOptions, CardStatusResponse, CardStatusResults,
} from './sadmin/admin/update-card-status/update-card-status.service.types';
import { CardStatusTransform } from './sadmin/admin/update-card-status/update-card-status.transform';
import { PrintReadyRequest } from './sadmin/admin/update-print-ready/update-print-ready.service';
import {
  PrintReadyService, PrintReadyOptions, PrintReadyResponse, PrintReadyResults,
} from './sadmin/admin/update-print-ready/update-print-ready.service.types';
import { PrintReadyTransform } from './sadmin/admin/update-print-ready/update-print-ready.transform';
import { ListAgntRequest } from './sadmin/agents/agent-list/list-agent.service';
import {
  ListAgntService, ListAgntOptions, ListAgntResponse, ListAgntResults,
} from './sadmin/agents/agent-list/list-agent.service.types';
import { ListAgntTransform } from './sadmin/agents/agent-list/list-agent.transform';
import { AgntStatusPutRequest } from './sadmin/agents/update-status/update-status.service';
import {
  AgntStatusPutService, AgntStatusPutOptions, AgntStatusPutResponse, AgntStatusPutResults,
} from './sadmin/agents/update-status/update-status.service.types';
import { AgntStatusPutTransform } from './sadmin/agents/update-status/update-status.transform';
import { createService } from './service';
import { CardMassStatusRequest } from './update-mass-card-status/update-mass-status.service';
import {
  CardMassStatusService, CardMassStatusOptions, CardMassStatusResponse, CardMassStatusResults,
} from './update-mass-card-status/update-mass-status.service.types';
import { CardMassStatusTransform } from './update-mass-card-status/update-mass-status.transform';
import { PassKeyPutRequest } from './update-passkey/update-passkey.service';
import {
  PassKeyPutService, PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults,
} from './update-passkey/update-passkey.service.types';
import { PassKeyPutTransform } from './update-passkey/update-passkey.transform';
import { PrintStatusRequest } from './update-print-status/update-print-status.service';
import {
  PrintStatusService, PrintStatusOptions, PrintStatusResponse, PrintStatusResults,
} from './update-print-status/update-print-status.service.types';
import { PrintStatusTransform } from './update-print-status/update-print-status.transform';
import { PrintIdRequest } from './update-printer-id/update-print-id.service';
import {
  PrintIdService, PrintIdOptions, PrintIdResponse, PrintIdResults,
} from './update-printer-id/update-print-id.service.types';
import { PrintIdTransform } from './update-printer-id/update-print-id.transform';
import { UserProfilePutRequest } from './update-profile/update-profile.service';
import {
  UserProfilePutService, UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults,
} from './update-profile/update-profile.service.types';
import { UserProfilePutTransform } from './update-profile/update-profile.transform';
import { CreateAWSFilesRequest } from './upload-AWS-files/create-awsfiles.service';
import {
  CreateAWSFilesService, CreateAWSFilesOptions, CreateAWSFilesResponse, CreateAWSFilesResults,
} from './upload-AWS-files/create-awsfiles.service.types';
import { CreateAWSFilesTransform } from './upload-AWS-files/create-awsfiles.transform';
import { CreateAvatarRequest } from './upload-avatar/create-avatar.service';
import {
  CreateAvatarService, CreateAvatarOptions, CreateAvatarResponse, CreateAvatarResults,
} from './upload-avatar/create-avatar.service.types';
import { CreateAvatarTransform } from './upload-avatar/create-avatar.transform';
import { UploadImageRequest } from './upload-image/upload-image.service';
import {
  UploadImageService, UploadImageOptions, UploadImageResponse, UploadImageResults,
} from './upload-image/upload-image.service.types';
import { UploadImageTransform } from './upload-image/upload-image.transform';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const registerService: RegisterService = createService<RegisterOptions, RegisterResponse, RegisterResults>(RegisterPostRequest, RegisterTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const forgotPasswordService: ForgotPasswordService = createService<ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults>(ForgotPasswordRequest, ForgotPasswordTransform);
export const fileURLService: FileURLService = createService<FileURLOptions, FileURLResponse, FileURLResults>(FileURLRequest, FileURLTransform);
export const cityMetaListService: ListCityService = createService<ListCityOptions, ListCityResponse, ListCityResults>(ListCityRequest, ListCityTransform);
export const userProfilePutService: UserProfilePutService = createService<UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults>(UserProfilePutRequest, UserProfilePutTransform);
export const userProfilePicService: UploadImageService = createService<UploadImageOptions, UploadImageResponse, UploadImageResults>(UploadImageRequest, UploadImageTransform);
export const passKeyService: PassKeyPutService = createService<PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults>(PassKeyPutRequest, PassKeyPutTransform);
export const stateListService: ListStateService = createService<ListStateOptions, ListStateResponse, ListStateResults>(ListStateRequest, ListStateTransform);
export const distListService: ListDistService = createService<ListDistOptions, ListDistResponse, ListDistResults>(ListDistRequest, ListDistTransform);
export const avatarPublishService: CreateAvatarService = createService<CreateAvatarOptions, CreateAvatarResponse, CreateAvatarResults>(CreateAvatarRequest, CreateAvatarTransform);
export const agntStatusUpdtService: AgntStatusPutService = createService<AgntStatusPutOptions, AgntStatusPutResponse, AgntStatusPutResults>(AgntStatusPutRequest, AgntStatusPutTransform);
export const agntListService: ListAgntService = createService<ListAgntOptions, ListAgntResponse, ListAgntResults>(ListAgntRequest, ListAgntTransform);
export const awsFilesUploadService: CreateAWSFilesService = createService<CreateAWSFilesOptions, CreateAWSFilesResponse, CreateAWSFilesResults>(CreateAWSFilesRequest, CreateAWSFilesTransform);
export const postRefFileService: PostRefFileService = createService<PostRefFileOptions, PostRefFileResponse, PostRefFileResults>(PostRefFileRequest, PostRefFileTransform);
export const refFileListService: ListRefFileService = createService<ListRefFileOptions, ListRefFileResponse, ListRefFileResults>(ListRefFileRequest, ListRefFileTransform);
export const checkRefFileService: CheckRefFileService = createService<CheckRefFileOptions, CheckRefFileResponse, CheckRefFileResults>(CheckRefFileRequest, CheckRefFileTransform);
export const putPrintReadyService: PutPrintReadyService = createService<PutPrintReadyOptions, PutPrintReadyResponse, PutPrintReadyResults>(PutPrintReadyRequest, PutPrintReadyTransform);
export const refStatusService: RefStatusService = createService<RefStatusOptions, RefStatusResponse, RefStatusResults>(RefStatusRequest, RefStatusTransform);
export const genPrintUrlService: PrintLinkService = createService<PrintLinkOptions, PrintLinkResponse, PrintLinkResults>(PrintLinkRequest, PrintLinkTransform);
export const cardStatusService: CardStatusService = createService<CardStatusOptions, CardStatusResponse, CardStatusResults>(CardStatusRequest, CardStatusTransform);
export const printReadyService: PrintReadyService = createService<PrintReadyOptions, PrintReadyResponse, PrintReadyResults>(PrintReadyRequest, PrintReadyTransform);
export const postPrintStatusService: PrintStatusService = createService<PrintStatusOptions, PrintStatusResponse, PrintStatusResults>(PrintStatusRequest, PrintStatusTransform);
export const talukaListService: ListTalukaService = createService<ListTalukaOptions, ListTalukaResponse, ListTalukaResults>(ListTalukaRequest, ListTalukaTransform);
export const talukabyAdmListService: ListTalukaAdmService = createService<ListTalukaAdmOptions, ListTalukaAdmResponse, ListTalukaAdmResults>(ListTalukaAdmRequest, ListTalukaAdmTransform);
export const awsFolderListService: GetAWSSubFolderService = createService<GetAWSSubFolderOptions, GetAWSSubFolderResponse, GetAWSSubFolderResults>(GetAWSSubFolderRequest, GetAWSSubFolderTransform);
export const talukabyLocListService: ListTalukaCountService = createService<ListTalukaCountOptions, ListTalukaCountResponse, ListTalukaCountResults>(ListTalukaCountRequest, ListTalukaCountTransform);
export const delFileURLService: DelFileURLService = createService<DelFileURLOptions, DelFileURLResponse, DelFileURLResults>(DelFileURLRequest, DelFileURLTransform);
export const subFolListService: ListSubFolCountService = createService<ListSubFolCountOptions, ListSubFolCountResponse, ListSubFolCountResults>(ListSubFolCountRequest, ListSubFolCountTransform);
export const prePrintZipService: PrePrintZipService = createService<PrePrintZipOptions, PrePrintZipResponse, PrePrintZipResults>(PrePrintZipRequest, PrePrintZipTransform);
export const checkUserService: CheckUserService = createService<CheckUserOptions, CheckUserResponse, CheckUserResults>(CheckUserRequest, CheckUserTransform);
export const downloadFileService: DownloadFileService = createService<DownloadFileOptions, DownloadFileResponse, DownloadFileResults>(DownloadFileRequest, DownloadFileTransform);
export const cardMassStatusService: CardMassStatusService = createService<CardMassStatusOptions, CardMassStatusResponse, CardMassStatusResults>(CardMassStatusRequest, CardMassStatusTransform);
export const cardCountStateWiseService: CardsStateWiseService = createService<CardsStateWiseOptions, CardsStateWiseResponse, CardsStateWiseResults>(CardsStateWiseRequest, CardsStateWiseTransform);
export const cardDashboardWiseService: CardDashboardService = createService<CardDashboardOptions, CardDashboardResponse, CardDashboardResults>(CardDashboardRequest, CardDashboardTransform);
export const cardTrendStatusService: CardTrendStatusService = createService<CardTrendStatusOptions, CardTrendStatusResponse, CardTrendStatusResults>(CardTrendStatusRequest, CardTrendStatusTransform);
export const getAllPrinterIdService: ListPrinterIdService = createService<ListPrinterIdOptions, ListPrinterIdResponse, ListPrinterIdResults>(ListPrinterIdRequest, ListPrinterIdTransform);
export const createPrinterIdService: PrintIdService = createService<PrintIdOptions, PrintIdResponse, PrintIdResults>(PrintIdRequest, PrintIdTransform);
export const printerDashboardService: PrinterDashboardService = createService<PrinterDashboardOptions, PrinterDashboardResponse, PrinterDashboardResults>(PrinterDashboardRequest, PrinterDashboardTransform);
export const superAdminDashboardService: SuperAdminDashboardService = createService<SuperAdminDashboardOptions, SuperAdminDashboardResponse, SuperAdminDashboardResults>(SuperAdminDashboardRequest, SuperAdminDashboardTransform);
export const superAdminTrendStsService: SuperAdminTrendStsService = createService<SuperAdminTrendStsOptions, SuperAdminTrendStsResponse, SuperAdminTrendStsResults>(SuperAdminTrendStsRequest, SuperAdminTrendStsTransform);
export const billingService: BillingService = createService<BillingOptions, BillingResponse, BillingResults>(BillingRequest, BillingTransform);
export const editBillingStatusService: EditBillingStatusService = createService<EditBillingStatusOptions, EditBillingStatusResponse, EditBillingStatusResults>(EditBillingStatusRequest, EditBillingStatusTransform);
export const superAdminTCountService: SuperAdminTCountService = createService<SuperAdminTCountOptions, SuperAdminTCountResponse, SuperAdminTCountResults>(SuperAdminTCountRequest, SuperAdminTCountTransform);
export const createTxnService: PostTxnService = createService<PostTxnOptions, PostTxnResponse, PostTxnResults>(PostTxnRequest, PostTxnTransform);
export const updateTxnService: UpdateTxnService = createService<UpdateTxnOptions, UpdateTxnResponse, UpdateTxnResults>(UpdateTxnRequest, UpdateTxnTransform);
export const getAllTxnService: GetAllTxnService = createService<GetAllTxnOptions, GetAllTxnResponse, GetAllTxnResults>(GetAllTxnRequest, GetAllTxnTransform);
export const getTxnReportService: GetTxnReportService = createService<GetTxnReportOptions, GetTxnReportResponse, GetTxnReportResults>(GetTxnReportRequest, GetTxnReportTransform);
