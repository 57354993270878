import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicCampusXcelComponent from '../../scenes/public/ACPS.component';
import CXUnauthorized from '../../scenes/unauthorized/unauthorized.component';
import { LoginSAdminComponent } from '../../scenes/public/sadmin.login.component';
import { SuperAdminComponent } from '../../scenes/exclusive/Super-Admin/super-admin.component';
import LoginPrintComponent from '../../scenes/public/print.login.component';
import { PrinterComponent } from '../../scenes/exclusive/Printer/printer.component';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/unathorized" element={<CXUnauthorized />} />
    <Route path="/" element={<PublicCampusXcelComponent />} />
    <Route path="/home" element={<PublicCampusXcelComponent />} />
    <Route path="/sadmin/login" element={<LoginSAdminComponent />} />
    <Route path="/sadmin/*" element={<SuperAdminComponent />} />
    <Route path="/print/login" element={<LoginPrintComponent />} />
    <Route path="/print/*" element={<PrinterComponent />} />
  </Routes>
);

export default AppBase;
