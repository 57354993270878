import React from 'react';
import { RootState } from 'typesafe-actions';
import {
  Button, Card, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Tooltip, UncontrolledTooltip,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../sadmin.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faBuildingColumns, faChalkboardUser, faCircleInfo, faFileCirclePlus, faHandHolding, faHandHoldingDollar, faHandHoldingHand, faIndianRupee, faMoneyBill1Wave, faMoneyCheck, faPrint, faSearch, faWallet,
} from '@fortawesome/free-solid-svg-icons';
import {
  clearBilling,
  clearGetAllTxn, getAllTxn, getBilling, modifyTxn, postTxn,
} from '../../../../store/yoCard/actions';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import LoaderData from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../utils/constants';
import { AdvDetails, TxnsResult } from '../../../../services/sadmin/accounts/get-all-transactions/get-all-txn.types';
import { UpdateTxnInput } from '../../../../services/sadmin/accounts/update-transactions/update-txn.types';
import { PostTxnInput } from '../../../../services/sadmin/accounts/add-transactions/post-txn.types';
import { BillingDetails } from '../../../../services/reports-all/superadmin-dashboard/superadmin-billing/billing.types';

export const TransactionTable: React.FC = () => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [loaderAdmin, setLoaderAdmin] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = React.useState('');
  const [txnLoader, setTxnLoader] = React.useState(false);
  const [crdCostOpen, setCrdCostOpen] = React.useState<Record<string, boolean>>({});
  const [openModalTxn, setOpenModalTxn] = React.useState<Record<string, boolean>>({});
  const [addTxnModal, setAddTxnModal] = React.useState(false);
  const [advTxnModal, setAdvTxnModal] = React.useState(false);
  const addNewTxnStatusResponse = useSelector((state: RootState) => state.yoCard.createTxn);
  const updateTxnStatusResponse = useSelector((state: RootState) => state.yoCard.updateTxn);
  const getTxnAllResponse = useSelector((state: RootState) => state.yoCard.getAllTxn);
  const [txnBalAmnt, setTxnBalAmnt] = React.useState(0);
  const [txnBalAmntArray, setTxnBalAmntArray] = React.useState([{ tCost: 0, miscCst: 0 }]);
  const [selectedBalances, setSelectedBalances] = React.useState<number[]>([]);
  const [txnData, setTxnData] = React.useState<TxnsResult[]>([]);
  const [txnAdvData, setTxnAdvData] = React.useState<AdvDetails[]>([]);
  const [totalAdvance, setTotalAdvance] = React.useState(0);
  const today = new Date().toISOString().split('T')[0];
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 10);
  const sevenDaysAgo = currentDate.toISOString().split('T')[0];
  const [startDate, setStartDate] = React.useState<string>(sevenDaysAgo);
  const [endDate, setEndDate] = React.useState<string>(new Date().toISOString().split('T')[0]);
  const billingResponse = useSelector((state: RootState) => state.yoCard.billingStatus);
  const [txnAddData, setTxnAddData] = React.useState<PostTxnInput>({
    amnt: '',
    txnDate: '',
    accTp: 'ADVC',
    txnUser: '',
    mPay: '',
    comments: '',
    prntDet: [],
    advCd: [],
    dbtTp: 'CLNT',
  });
  const [txnUpdateData, setTxnUpdateData] = React.useState<UpdateTxnInput>({
    amnt: '',
    txnDate: '',
    accTp: '',
    txnUser: '',
    mPay: '',
    comments: '',
    prntDet: [],
    // prntDt: '',
    // prntCd: '',
    dbtTp: 'CLNT',
  });
  const location = useLocation();
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = React.useState('');
  const totalMBal = selectedBalances.reduce((acc, item) => acc + item, 0);
  const [billingStatus, setBillingStatus] = React.useState<BillingDetails[]>([{
    PrntId: '',
    stData: {
      distNme: '',
      stCd: '',
      stNme: '',
    },
    PrntTp: '',
    costPrint: '',
    stsClient: '',
    stsVendor1: '',
    stsPrnt: '',
    gstVendor1: '0',
    stsZameer: '',
    cSts: '',
    updtOn: '',
    prntUsrCd: {
      st: '',
      ph: '',
      UserCd: '',
      lNme: '',
      fNme: '',
      eId: '',
    },
    updtCnt: 0,
    costVendor1: '',
    cnt: '0',
    costCrd: '0',
    gstCrd: '0',
    gstPrint: '0',
    cardCost: '0',
    gstCardCost: '0',
    tCrdCost: '',
    tPrntCost: '',
    tVendor1Cost: '',
    costWDS: '0',
    gstWDS: '0',
    tWDSCost: '',
    costZameer: '0',
    gstZameer: '0',
    tZameerCost: '',
    tWDSProf: '',
    gstWDSProf: '',
    costWDSProf: '',
    courURL: '',
    balAmnt: '',
    miscCst: '0',
    courTp: '',
    courTpVal: '',
    courId: '',
  }]);

  React.useEffect(() => {
    if (path[2] === 'accounts') {
      setMenuItems('accounts');
    }
  }, [path]);

  const handleTxnType = (txn_tp: string) => {
    setTxnAddData({ ...txnAddData, accTp: txn_tp });
  };

  const toggleCardCost = (target: string) => {
    setCrdCostOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handlePayMode = (pay_mode: string) => {
    setTxnAddData({ ...txnAddData, mPay: pay_mode });
  };

  const handleDbtType = (dbt_tp: string) => {
    setTxnAddData({ ...txnAddData, dbtTp: dbt_tp });
  };

  const handleFieldChangeTxn = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'amnt') {
      setTxnBalAmnt(parseFloat(e.target.value));
      setTxnAddData({ ...txnAddData, amnt: e.target.value });
    } else {
      setTxnAddData({ ...txnAddData, [e.target.name]: e.target.value });
    }
  };

  const handleTxnUpdateType = (txn_tp: string) => {
    setTxnUpdateData({ ...txnUpdateData, accTp: txn_tp });
    if (txn_tp === 'CRDT') {
      setTxnUpdateData({ ...txnUpdateData, accTp: txn_tp, dbtTp: 'CLNT' });
    }
  };
  // console.log(txnUpdateData);
  const handlePayUpdateMode = (pay_mode: string) => {
    setTxnUpdateData({ ...txnUpdateData, mPay: pay_mode });
  };

  const handleFieldUpdateChangeTxn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxnUpdateData({ ...txnUpdateData, [e.target.name]: e.target.value });
  };

  const handleUpdateDbtType = (dbt_tp: string) => {
    setTxnUpdateData({ ...txnUpdateData, dbtTp: dbt_tp });
  };

  const handleStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  // const handlePrintDetails = (printDate: string, printCode: string) => {
  //   setTxnAddData({ ...txnAddData, prntDt: printDate, prntCd: printCode });
  // };

  const handleDebitBlance = (tBal: number, advBalArray: AdvDetails[]) => {
    const advTotal = advBalArray.map((adv) => adv.prntBal + adv.rzBal + adv.vndr1Bal).reduce((total, bal) => total + bal, 0);
    const remainingBalance = tBal - advTotal;
    console.log('Remaining Balance:', advTotal, tBal);
    return remainingBalance;
  };

  React.useEffect(() => {
    if (txnAddData.accTp === 'CRDT') {
      const totalCost = txnBalAmntArray.reduce((acc, obj) => acc + obj.tCost, 0);
      const misCost = txnBalAmntArray.reduce((acc, obj) => acc + obj.miscCst, 0);
      const remainingBalance = parseFloat(txnAddData.amnt) - (totalCost + misCost);
      setTxnBalAmnt(parseFloat(remainingBalance.toFixed(2)));
    } else if (txnAddData.accTp === 'DBT') {
      const totalDebitCost = txnBalAmntArray.reduce((acc, obj) => acc + obj.tCost, 0);
      const miscDebitCost = txnBalAmntArray.reduce((acc, obj) => acc + obj.miscCst, 0);
      const remainingDebitBalance = parseFloat(txnAddData.amnt) - (totalDebitCost + miscDebitCost);
      setTxnBalAmnt(parseFloat(remainingDebitBalance.toFixed(2)));
      console.log('Check 1', txnBalAmntArray);
      // const remBal = handleDebitBlance(totalAdvance, txnAdvData) - parseFloat(txnAddData.amnt);
      // setTxnBalAmnt(remBal);
    }
  }, [txnBalAmnt, txnBalAmntArray, totalAdvance, txnAddData.amnt, txnAdvData]);

  const handlePrintDetails = (printDate: string, printCode: string, totalCost: string) => {
    const idTpString = `${printDate}#${printCode}`;
    console.log('Checking Costieer', totalCost);
    if (txnAddData.prntDet.includes(idTpString)) {
      const filteredArray = txnBalAmntArray.filter((item) => item.tCost !== parseFloat(totalCost));
      setTxnBalAmntArray(filteredArray);
      setTxnAddData({
        ...txnAddData,
        prntDet: txnAddData.prntDet.filter((item) => item !== idTpString),
      });
    } else {
      setTxnBalAmntArray([...txnBalAmntArray, { tCost: parseFloat(totalCost), miscCst: 0 }]);
      setTxnAddData({
        ...txnAddData,
        prntDet: [...txnAddData.prntDet, idTpString],
      });
    }
  };

  console.log('Check Add Bal', totalMBal, txnAddData);
  const handleAdvanceDetails = (AccId: string, mBal: string, prntBal: number, vndr1Bal: number, rzBal: number, isChecked: boolean) => {
    const actBal = parseFloat(mBal) - (prntBal + vndr1Bal + rzBal);
    if (isChecked) {
      setSelectedBalances([...selectedBalances, actBal]);
      setTxnAddData({
        ...txnAddData,
        advCd: [...txnAddData.advCd, AccId],
      });
    } else {
      setSelectedBalances(selectedBalances.filter((item) => item !== actBal));
      setTxnAddData({
        ...txnAddData,
        advCd: txnAddData.advCd.filter((item) => item !== AccId),
      });
    }
  };

  const handleNewBlance = (mBal: string, prntBal: number, vndr1Bal: number, rzEntBal: number) => parseFloat(mBal) - (prntBal + vndr1Bal + rzEntBal);

  const txnToggleModal = (target: string, rowData: any) => {
    setOpenModalTxn((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setTxnUpdateData({
      ...txnUpdateData,
      amnt: rowData.amnt !== '' ? rowData.amnt : '',
      txnDate: rowData.AccTp !== '' ? rowData.AccTp : '',
      dbtTp: rowData.dbtTp !== '' ? rowData.dbtTp : '',
      accTp: rowData.accTp !== '' ? rowData.accTp : '',
      txnUser: rowData.txnUser !== '' ? rowData.txnUser : '',
      mPay: rowData.mPay !== '' ? rowData.mPay : '',
      comments: rowData.comments !== '' ? rowData.comments : '',
    });
  };

  const updateTxnData = (txnId: string) => {
    setTxnLoader(true);
    dispatch(modifyTxn({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_BILLING_ACCOUNTS,
      txnId,
      inputBody: txnUpdateData,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (updateTxnStatusResponse.error && updateTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(updateTxnStatusResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateTxnStatusResponse.error, updateTxnStatusResponse.message]);

  React.useEffect(() => {
    if (!updateTxnStatusResponse.error && updateTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(updateTxnStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [updateTxnStatusResponse.error, updateTxnStatusResponse.message]);

  React.useEffect(() => {
    if (menuItems === 'accounts') {
      setTxnLoader(true);
      dispatch(getAllTxn({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_BILLING_ACCOUNTS,
        sDt: startDate,
        eDt: endDate,
        token: tokenData,
      }));
    }
  }, [menuItems]);

  const handleTxnSearch = () => {
    setTxnLoader(true);
    dispatch(getAllTxn({
      userType: userTp,
      requestType: APIHeader.REQ_GET_ALL_BILLING_ACCOUNTS,
      sDt: startDate,
      eDt: endDate,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (getTxnAllResponse.isError && getTxnAllResponse.message !== '') {
      setTxnLoader(false);
      setErrorShowAlert(true);
      setAlertAPIMsg(getTxnAllResponse.message);
      dispatch(clearGetAllTxn());
    }
  }, [getTxnAllResponse.isError, getTxnAllResponse.message]);

  React.useEffect(() => {
    if (!getTxnAllResponse.isError && getTxnAllResponse.message === 'executed') {
      setTxnLoader(false);
      setTxnData(getTxnAllResponse.data.accDetails);
      setTxnAdvData(getTxnAllResponse.data.advDetail.advData);
      setTotalAdvance(getTxnAllResponse.data.advDetail.tAdvBal);
      dispatch(clearGetAllTxn());
    }
  }, [getTxnAllResponse.isError, getTxnAllResponse.message]);

  const txnToggleAddTxnModal = () => {
    setAddTxnModal(!addTxnModal);
  };

  const txnToggleAdvanceTxnModal = () => {
    setAdvTxnModal(!advTxnModal);
  };

  const handleAddNewTxn = () => {
    setTxnLoader(true);
    dispatch(postTxn({
      userType: userTp,
      requestType: APIHeader.REQ_ADD_BILLING_ACCOUNTS,
      inputBody: txnAddData,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (addNewTxnStatusResponse.error && addNewTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(addNewTxnStatusResponse.message);
      setErrorShowAlert(true);
    }
  }, [addNewTxnStatusResponse.error, addNewTxnStatusResponse.message]);

  React.useEffect(() => {
    if (!addNewTxnStatusResponse.error && addNewTxnStatusResponse.message !== '') {
      setTxnLoader(false);
      setAlertAPIMsg(addNewTxnStatusResponse.message);
      setSuccessShowAlert(true);
    }
  }, [addNewTxnStatusResponse.error, addNewTxnStatusResponse.message]);

  const handleBillingSearch = () => {
    setLoaderAdmin(true);
    Promise.resolve(
      dispatch(getBilling({
        userType: userTp,
        requestType: APIHeader.REQ_GET_CARD_BILLS,
        sDt: startDate,
        eDt: endDate,
        filtrTp: 'STATUS',
        filtrSts: '',
        token: tokenData,
      })),
    ).then(() => {
      dispatch(clearBilling());
    });
  };

  React.useEffect(() => {
    if (billingResponse.isError && billingResponse.message !== '') {
      setLoaderAdmin(false);
      setErrorShowAlert(true);
      setAlertAPIMsg(billingResponse.message);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  React.useEffect(() => {
    if (!billingResponse.isError && billingResponse.message === 'executed') {
      setLoaderAdmin(false);
      setBillingStatus(billingResponse.data);
      dispatch(clearBilling());
    }
  }, [billingResponse.isError, billingResponse.message]);

  return (
    <div>
      {txnLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row>
        <Col lg="3" xs="12">
          <div className="print-sadmin-card-box pt-3">
            <div className="align-center ">
              <Button className="button-icon" onClick={() => txnToggleAdvanceTxnModal()}>
                <div className="stopr-circle_container">
                  <div className="stopr-circle_main">
                    <div className="txn-adv-circle_text_container align-center">
                      <div className="txn-adv-circle_text">
                        {totalAdvance.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="text-lite-grey">click here</span>
              </Button>
            </div>
            <div className="my-1 align-center ">
              <span className="print-status-pending">Advance Payments(rs.)</span>
            </div>
          </div>
          <Modal
            isOpen={advTxnModal}
            className="pt-5 adv-table-modal"
          >
            <ModalHeader toggle={() => txnToggleAdvanceTxnModal()} className="form-card-border-none mx-3 my-2" />
            <div className="mx-2 pr-1 pt-2">
              <h6 className="header-txn-bg">Advance Amount</h6>
            </div>
            <ModalBody className="padding-zero mx-3">
              <div className="adv-table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Transaction Date</th>
                      <th>Amount(Rs)</th>
                      <th>Account Type</th>
                      <th>Payment Mode</th>
                      <th>Printer Payment</th>
                      <th>Vendor#1 Payment</th>
                      <th>RZ Ent Payment</th>
                      <th>Txn User</th>
                      <th>Comments</th>
                    </tr>
                  </thead>
                  <tbody>
                    {txnAdvData && txnAdvData.map((advData) => (
                      <tr key={`row-${advData.AccId}-${advData.AccTp}`}>
                        <td>
                          {advData.txnDt}
                        </td>
                        <td>
                          {advData.mBal.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                        </td>
                        <td>
                          {advData.accTp}
                        </td>
                        <td>
                          {advData.mPay}
                        </td>
                        <td>
                          {advData.prntBal && advData.prntBal.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                        </td>
                        <td>
                          {advData.vndr1Bal && advData.vndr1Bal.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                        </td>
                        <td>
                          {advData.rzBal && advData.rzBal.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                        </td>
                        <td>
                          {advData.txnUser}
                        </td>
                        <td>
                          {advData.comments}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pt-4" />
              </div>
            </ModalBody>
          </Modal>
        </Col>
        <Col lg="9" xs="12">
          <div className="print-sadmin-card-box my-2">
            <div className="align-end pt-1">
              <Button id="add-txn" className="button-icon" onClick={() => txnToggleAddTxnModal()}>
                <FontAwesomeIcon className="add-txn-icon" icon={faFileCirclePlus} />
              </Button>
              <UncontrolledTooltip delay={0} target="add-txn">
                Add New Transactions
              </UncontrolledTooltip>
              <Modal
                isOpen={addTxnModal}
                className="pt-5"
              >
                <ModalHeader toggle={() => txnToggleAddTxnModal()} className="form-card-border-none mx-3 my-2" />
                <div className="mx-2 pr-1 pt-2">
                  <h6 className="header-txn-bg">Add New Transaction</h6>
                </div>
                <ModalBody className="padding-zero mx-3">
                  <Row className="pt-3 align-start">
                    <Col lg="12">
                      <Row className="align-start pt-1">
                        <h6 className="input-header-text my-1">Transaction Type<span className="mandate-star">*</span></h6>
                        <Col xs="4" lg="4" className="pt-1">
                          <Input
                            type="radio"
                            name="accTp"
                            checked={txnAddData.accTp === 'ADVC'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handleTxnType('ADVC')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            ADVANCE <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingHand} />
                          </Label>
                        </Col>
                        <Col xs="4" lg="4" className="pt-1">
                          <Input
                            type="radio"
                            name="accTp"
                            checked={txnAddData.accTp === 'CRDT'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handleTxnType('CRDT')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            CREDIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingDollar} />
                          </Label>
                        </Col>
                        <Col xs="4" lg="4" className="pt-1">
                          <Input
                            type="radio"
                            name="accTp"
                            checked={txnAddData.accTp === 'DBT'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handleTxnType('DBT')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            DEBIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHolding} />
                          </Label>
                        </Col>
                        {txnAddData.accTp === 'DBT'
                          ? (
                            <Col xs="12" className="mx-1">
                              <h6 className="print-status-pending my-1 pt-2">Transaction To<span className="mandate-star">*</span></h6>
                              <Row className="pt-1 mx-1">
                                <Col xs="4" lg="4">
                                  <Input
                                    type="radio"
                                    name="dbtTp"
                                    checked={txnAddData.dbtTp === 'PRNT'}
                                    className="acps-radio-box cursor-pointer my-1"
                                    onChange={() => handleDbtType('PRNT')}
                                  />
                                  <Label check className="credit-tp-text mx-1">
                                    Printer
                                  </Label>
                                </Col>
                                <Col xs="4" lg="4">
                                  <Input
                                    type="radio"
                                    name="dbtTp"
                                    checked={txnAddData.dbtTp === 'VNDR1'}
                                    className="acps-radio-box cursor-pointer my-1"
                                    onChange={() => handleDbtType('VNDR1')}
                                  />
                                  <Label check className="credit-tp-text mx-1">
                                    Vendor#1
                                  </Label>
                                </Col>
                                <Col xs="4" lg="4">
                                  <Input
                                    type="radio"
                                    name="dbtTp"
                                    checked={txnAddData.dbtTp === 'RZENT'}
                                    className="acps-radio-box cursor-pointer my-1"
                                    onChange={() => handleDbtType('RZENT')}
                                  />
                                  <Label check className="credit-tp-text mx-1">
                                    RZ Ent.
                                  </Label>
                                </Col>
                              </Row>
                            </Col>
                          ) : null }
                      </Row>
                    </Col>
                    {txnAddData.accTp === 'CRDT' || txnAddData.accTp === 'DBT'
                      ? (
                        <Col lg="12">
                          <div className="pt-4">
                            <h6 className="input-header-text">Total remaining advance<span className="mandate-star">*</span>(<span className="td-text-lite-main-bold">{handleDebitBlance(totalAdvance, txnAdvData).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</span> rs.)</h6>
                            <div className="align-start pt-2">
                              {txnAdvData && txnAdvData.map((advAmnt) => (
                                <div key={`row-${advAmnt.AccId}-${advAmnt.AccTp}`} className="mx-1">
                                  <Input
                                    type="checkbox"
                                    className="acps-radio-box cursor-pointer my-1"
                                    onChange={(e) => handleAdvanceDetails(advAmnt.AccId, advAmnt.mBal, advAmnt.prntBal, advAmnt.vndr1Bal, advAmnt.rzBal, e.target.checked)}
                                  />
                                  <Label check className="credit-tp-text mx-1">
                                    {handleNewBlance(advAmnt.mBal, advAmnt.prntBal, advAmnt.vndr1Bal, advAmnt.rzBal).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      ) : null }
                    {txnAddData.accTp === 'DBT'
                      ? (
                        <Col lg="12">
                          {/* <div className="pt-4">
                            <h6 className="input-header-text">Total Advance Payments (Rs)<span className="mandate-star">*</span></h6>
                            <div className="align-start pt-2">
                              {txnAdvData && txnAdvData.map((advAmnt) => (
                                <div key={`debitrow-${advAmnt.AccId}-${advAmnt.AccTp}`} className="mx-1">
                                  <Input
                                    type="checkbox"
                                    className="acps-radio-box cursor-pointer my-1"
                                    onChange={(e) => handleAdvanceDetails(
                                      advAmnt.AccId,
                                      advAmnt.mBal,
                                      txnAddData.dbtTp === 'CLNT' ? advAmnt.crdtBal
                                        : txnAddData.dbtTp === 'PRNT' ? advAmnt.prntBal
                                          : txnAddData.dbtTp === 'VNDR1' ? advAmnt.vndr1Bal
                                            : txnAddData.dbtTp === 'RZENT' ? advAmnt.rzBal
                                              : 0,
                                      e.target.checked,
                                    )}
                                  />
                                  {
                                    txnAddData.dbtTp === 'CLNT'
                                      ? (
                                        <Label check className="credit-tp-text mx-1">
                                          {handleNewBlance(advAmnt.mBal, advAmnt.crdtBal).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                        </Label>
                                      )
                                      : txnAddData.dbtTp === 'PRNT'
                                        ? (
                                          <Label check className="credit-tp-text mx-1">
                                            {handleNewBlance(advAmnt.mBal, advAmnt.prntBal).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                          </Label>
                                        )
                                        : txnAddData.dbtTp === 'VNDR1'
                                          ? (
                                            <Label check className="credit-tp-text mx-1">
                                              {handleNewBlance(advAmnt.mBal, advAmnt.vndr1Bal).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                            </Label>
                                          ) : txnAddData.dbtTp === 'RZENT'
                                            ? (
                                              <Label check className="credit-tp-text mx-1">
                                                {handleNewBlance(advAmnt.mBal, advAmnt.rzBal).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                                              </Label>
                                            ) : null
                                }
                                </div>
                              ))}
                            </div>
                          </div> */}
                          <div className="pt-2">
                            <h6 className="accounts-card-icon-lite">Remaining Advance(rs.):
                              <Label className="td-text-lite-main-bold mx-1">
                                {handleDebitBlance(totalAdvance, txnAdvData).toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}
                              </Label>
                              <span className="accounts-card-icon-lite">out of</span>
                              <Label className="td-text-lite-main-bold mx-1">{totalAdvance.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</Label>
                            </h6>
                          </div>
                        </Col>
                      ) : null }
                    <Col lg="7">
                      <div className="pt-4">
                        <h6 className="input-header-text">Transaction Amount(rs.)<span className="mandate-star">*</span></h6>
                        <Input
                          type="number"
                          name="amnt"
                          required
                          onChange={handleFieldChangeTxn}
                          className="yocard-input-ref"
                        />
                        {txnAddData.accTp === 'CRDT'
                          ? (parseFloat(txnAddData.amnt) > totalMBal
                            ? <span className="td-text-lite-main-bold mx-1">Amount is greater than advance</span>
                            : <span className="accounts-card-icon-lite mx-1">Amount cannot be greater than advance.</span>
                          ) : txnAddData.accTp === 'DBT'
                            ? (handleDebitBlance(totalAdvance, txnAdvData) < parseFloat(txnAddData.amnt) || parseFloat(txnAddData.amnt) > totalMBal
                              ? <span className="td-text-lite-main-bold mx-1">Amount is greater</span>
                              : <span className="accounts-card-icon-lite mx-1">Amount cannot be greater than advance</span>
                            ) : <span className="accounts-card-icon-lite mx-1">Amount cannot be greater than advance</span>}
                      </div>
                    </Col>
                    <Col lg="5">
                      <div className="pt-4">
                        <h6 className="input-header-text">Transaction Date<span className="mandate-star">*</span></h6>
                        <Input
                          type="date"
                          name="txnDate"
                          required
                          onChange={handleFieldChangeTxn}
                          className="yocard-input-ref"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      {txnAddData.accTp !== 'ADVC'
                        ? (
                          <Row className="align-start pt-4">
                            <h6 className="input-header-text my-1">Tag Transactions(non reversible)<span className="mandate-star">*</span></h6>
                            <div className="print-sadmin-card-box my-2">
                              <Row>
                                <Col lg="5" xs="10">
                                  <div className="pt-1">
                                    <h6 className="input-header-text">Start Date</h6>
                                    <Input
                                      value={startDate}
                                      type="date"
                                      name="refDt"
                                      onChange={handleStartDate}
                                      className="yocard-input-ref"
                                    />
                                  </div>
                                </Col>
                                <Col lg="5" xs="10">
                                  <div className="pt-1">
                                    <h6 className="input-header-text">End Date</h6>
                                    <Input
                                      max={today}
                                      value={endDate}
                                      type="date"
                                      name="refDt"
                                      onChange={handleEndDate}
                                      className="yocard-input-ref"
                                    />
                                  </div>
                                </Col>
                                <Col lg="2">
                                  <div className="align-end pt-4 mx-2">
                                    <Button className="button-icon" onClick={() => handleBillingSearch()}>
                                      <FontAwesomeIcon className="accounts-header my-1" icon={faSearch} />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              {billingStatus.length === 0 ? (
                                <div className="pt-5 align-center">
                                  <span className="form-collapse-card-header">No Record Found</span>
                                </div>
                              )
                                : (
                                  <div>
                                    <span className="accounts-card-icon-lite">Transaction Balance left: </span>
                                    <span className="td-text-lite-main-bold">{txnBalAmnt}</span>
                                    <table className="styled-table">
                                      <thead>
                                        <tr>
                                          <th>Action</th>
                                          <th>Card Info</th>
                                          <th>Total Cards</th>
                                          <th>Amount(Rs)</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {billingStatus && billingStatus.map((row, rowIndex) => (
                                          <tr key={`row-${row.PrntId}-${row.PrntTp}`}>
                                            <td className="">
                                              <Input
                                                type="checkbox"
                                                disabled={
                                                  txnAddData.accTp === 'CRDT'
                                                    ? row.stsClient === 'Completed from Client'
                                                    : txnAddData.accTp === 'DBT'
                                                      ? !!(row.stsPrnt === 'Printer Payment Completed' && row.stsZameer === 'RZ Ent. Payment Completed' && row.stsVendor1 === 'Vendor#1 Payment Completed')
                                                      : true
                                                }
                                      // checked={txnAddData.prntDt === row.PrntId && txnAddData.prntCd === row.PrntTp}
                                                className="acps-radio-box cursor-pointer my-1"
                                                onChange={() => handlePrintDetails(
                                                  row.PrntId,
                                                  row.PrntTp,
                                                  txnAddData.accTp === 'CRDT' ? row.tCrdCost
                                                    : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'PRNT' ? row.tPrntCost
                                                      : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'VNDR1' ? row.tVendor1Cost
                                                        : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'RZENT' ? row.tZameerCost : '0',
                                                )}
                                              />
                                            </td>
                                            <td>
                                              <div>
                                                <span className="td-text">{row.PrntId}</span>
                                              </div>
                                              <div>
                                                <span className="td-text">{row.stData.distNme}({row.stData.stCd})</span>
                                              </div>
                                            </td>
                                            <td><span className="td-text">{row.cnt}</span></td>
                                            <td>
                                              <span className="td-text">
                                                {txnAddData.accTp === 'CRDT' ? row.tCrdCost
                                                  : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'PRNT' ? row.tPrntCost
                                                    : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'VNDR1' ? row.tVendor1Cost
                                                      : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'RZENT' ? row.tZameerCost : 0}
                                              </span>
                                              <Button className="button-icon" id={`cardCost-${rowIndex}`}>
                                                <FontAwesomeIcon className="info-stadm-icon my-1" icon={faCircleInfo} />
                                              </Button>
                                              <Tooltip
                                                placement="top"
                                                isOpen={crdCostOpen[`cardCost-${rowIndex}`] || false}
                                                target={`cardCost-${rowIndex}`}
                                                toggle={() => toggleCardCost(`cardCost-${rowIndex}`)}
                                              >
                                                <span className="td-text-highlight">Cost Breakup</span><br />
                                                {txnAddData.accTp === 'CRDT'
                                                  ? (
                                                    <span className="td-text-highlight">
                                                      Cost: {row.cardCost} Rs/- <br />
                                                      GST: {row.gstCardCost} Rs/-
                                                    </span>
                                                  )
                                                  : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'PRNT' ? (
                                                    <span className="td-text-highlight">
                                                      Cost: {row.costPrint} Rs/- <br />
                                                      GST: {row.gstPrint} Rs/-
                                                    </span>
                                                  )
                                                    : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'VNDR1' ? (
                                                      <span className="td-text-highlight">
                                                        Cost: {row.costVendor1} Rs/- <br />
                                                        GST: {row.gstVendor1} Rs/-
                                                      </span>
                                                    )
                                                      : txnAddData.accTp === 'DBT' && txnAddData.dbtTp === 'RZENT' ? (
                                                        <span className="td-text-highlight">
                                                          Cost: {row.costZameer} Rs/- <br />
                                                          GST: {row.gstZameer} Rs/-
                                                        </span>
                                                      ) : 0}
                                              </Tooltip>
                                            </td>
                                            <td>
                                              <div className="align-start">
                                                <div className="mx-1">
                                                  <FontAwesomeIcon className={row.stsPrnt === 'Printer Payment Pending' ? 'status-icon-pending-txn' : 'status-icon-completed-txn'} icon={faPrint} id={`printStatus-${rowIndex}`} />
                                                  <UncontrolledTooltip delay={0} target={`printStatus-${rowIndex}`}>
                                                    {row.stsPrnt}
                                                  </UncontrolledTooltip>
                                                </div>
                                                <div className="ml-1">
                                                  <FontAwesomeIcon className={row.stsVendor1 === 'Vendor#1 Payment Pending' ? 'status-icon-pending-txn' : 'status-icon-completed-txn'} icon={faCircleUser} id={`vendorStatus-${rowIndex}`} />
                                                  <UncontrolledTooltip delay={0} target={`vendorStatus-${rowIndex}`}>
                                                    {row.stsVendor1}
                                                  </UncontrolledTooltip>
                                                </div>
                                                <div className="ml-1">
                                                  <FontAwesomeIcon className={row.stsZameer === 'RZ Ent. Payment Pending' ? 'status-icon-pending-txn' : 'status-icon-completed-txn'} icon={faChalkboardUser} id={`rzStatus-${rowIndex}`} />
                                                  <UncontrolledTooltip delay={0} target={`rzStatus-${rowIndex}`}>
                                                    {row.stsZameer}
                                                  </UncontrolledTooltip>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              {loaderAdmin ? (
                                <Spinner
                                  style={{ color: '#FF7F50' }}
                                  type="grow"
                                >
                                  Loading...
                                </Spinner>
                              ) : null}
                            </div>
                          </Row>
                        ) : null}
                    </Col>
                    <Col lg="12">
                      <Row className="align-start pt-4">
                        <h6 className="input-header-text my-1">Payment Mode<span className="mandate-star">*</span></h6>
                        <Col xs="12" lg="6" className="pt-2">
                          <Input
                            type="radio"
                            name="mPay"
                            checked={txnAddData.mPay === 'CHQ'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handlePayMode('CHQ')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            Cheque <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyCheck} />
                          </Label>
                        </Col>
                        <Col xs="12" lg="6" className="pt-2">
                          <Input
                            type="radio"
                            name="mPay"
                            checked={txnAddData.mPay === 'UPI'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handlePayMode('UPI')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            UPI <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faWallet} />
                          </Label>
                        </Col>
                        <Col xs="12" lg="6" className="pt-2">
                          <Input
                            type="radio"
                            name="mPay"
                            checked={txnAddData.mPay === 'BNK'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handlePayMode('BNK')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            Net Banking <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faBuildingColumns} />
                          </Label>
                        </Col>
                        <Col xs="12" lg="6" className="pt-2">
                          <Input
                            type="radio"
                            name="mPay"
                            checked={txnAddData.mPay === 'CSH'}
                            className="acps-radio-box cursor-pointer my-1"
                            onChange={() => handlePayMode('CSH')}
                          />
                          <Label check className="credit-tp-text mx-1">
                            Cash <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyBill1Wave} />
                          </Label>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="12">
                      <div className="pt-4">
                        <h6 className="input-header-text">Transaction done by<span className="mandate-star">*</span></h6>
                        <Input
                          type="text"
                          name="txnUser"
                          required
                          onChange={handleFieldChangeTxn}
                          className="yocard-input-ref"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="pt-4">
                        <h6 className="input-header-text">Comments</h6>
                        <Input
                          type="textarea"
                          name="comments"
                          onChange={handleFieldChangeTxn}
                          className="acps-text-area"
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="align-center my-1 pt-3">
                    <Button
                      disabled={
                  txnAddData.accTp === '' || txnAddData.txnDate === '' || txnAddData.amnt === '' || txnBalAmnt < 0 || (txnAddData.accTp === 'CRDT' ? parseFloat(txnAddData.amnt) > totalMBal : txnAddData.accTp === 'DBT' ? handleDebitBlance(totalAdvance, txnAdvData) < parseFloat(txnAddData.amnt) : false)
                      }
                      className="acps-button-oval"
                      onClick={() => handleAddNewTxn()}
                    >Save
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
            <Row className="align-center">
              <Col lg="5" xs="12">
                <div className="pt-1">
                  <h6 className="input-header-text">Start Date</h6>
                  <Input
                    value={startDate}
                    type="date"
                    name="refDt"
                    onChange={handleStartDate}
                    className="acps-input-ref"
                  />
                </div>
              </Col>
              <Col lg="5">
                <div className="pt-1">
                  <h6 className="input-header-text">End Date</h6>
                  <Input
                    max={today}
                    value={endDate}
                    type="date"
                    name="refDt"
                    onChange={handleEndDate}
                    className="acps-input-ref"
                  />
                </div>
              </Col>
              <Col lg="10">
                <div className="my-2 align-end">
                  <div className="pt-1">
                    <Button className="acps-button-oval mx-1" onClick={() => handleTxnSearch()}>
                      Search
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {txnData.length === 0 ? (
        <div className="pt-5 align-center">
          <span className="form-collapse-card-header">No Transactions Found</span>
        </div>
      ) : (
        <table className="styled-table">
          <thead>
            <tr>
              <th>Txn Date</th>
              <th>Txn Amount<FontAwesomeIcon className="accounts-card-icon-lite mx-1" icon={faIndianRupee} /></th>
              <th>Txn Type</th>
              <th>Txn(to/from)</th>
              <th>Txn User</th>
              <th>Payment Mode</th>
              <th>Comments</th>
              <th>Print Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {txnData.length && txnData.map((row, rowIndex) => (
              <tr key={`txn-${rowIndex.toString()}`}>
                <td>
                  <span className="td-text">{row.AccTp}</span>
                </td>
                <td>
                  <span className="td-text">{row.amnt.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')}</span>
                </td>
                <td>
                  <span className="td-text">{row.accTpVal}</span>
                </td>
                <td>
                  <span className="td-text">{row.dbtTpVal}</span>
                </td>
                <td>
                  <span className="td-text">{row.txnUser}</span>
                </td>
                <td><span className="td-text">{row.mPayVal}</span></td>
                <td><span className="td-text">{row.comments}</span></td>
                <td>
                  {row.cardDet.length && row.cardDet.map((cardDataDet, crdIndex) => (
                    <Card key={`txn-${crdIndex.toString()}`} className="form-card-border my-1">
                      <div className="mx-1">
                        <span className="text-lite-grey">Cards: </span>
                        <span className="td-text">{cardDataDet.tCnt}</span>
                      </div>
                      <div className="mx-1">
                        <span className="text-lite-grey">Date: </span>
                        <span className="td-text">{cardDataDet.CrdDt}</span>
                      </div>
                      <div className="mx-1">
                        <span className="td-text">{cardDataDet.CrdCd}</span>
                      </div>
                      <div className="mx-1">
                        <span className="td-text">{cardDataDet.stData.distNme}({cardDataDet.stData.stCd})</span>
                      </div>
                    </Card>
                  ))}
                </td>
                <td>
                  <span className="align-center">
                    <Button id={`txnDet-${(rowIndex + 1).toString()}`} className="button-icon mx-1" onClick={() => txnToggleModal(`txnDet-${rowIndex}`, row)}>
                      <FontAwesomeIcon className="collapse-icon mx-1" icon={faEdit} />
                    </Button>
                    <UncontrolledTooltip delay={0} target={`txnDet-${(rowIndex + 1).toString()}`}>
                      Edit Costing, Courier & Count
                    </UncontrolledTooltip>
                  </span>
                  <Modal
                    isOpen={openModalTxn[`txnDet-${rowIndex}`] || false}
                    target={`txnDet-${rowIndex}`}
                    className="pt-5"
                  >
                    <ModalHeader toggle={() => txnToggleModal(`txnDet-${rowIndex}`, row)} className="form-card-border-none mx-3 my-2" />
                    <div className="ml-1">
                      <span className="text-lite-grey">Date: {row.AccTp}</span>
                    </div>
                    <ModalBody className="padding-zero mx-3">
                      <Row className="pt-3 align-start">
                        <Col lg="12">
                          <Row className="align-start pt-1">
                            <h6 className="input-header-text my-1">Transaction Type<span className="mandate-star">*</span></h6>
                            <Col xs="4" lg="4" className="pt-1">
                              <Input
                                type="radio"
                                name="accTp"
                                disabled
                                checked={txnUpdateData.accTp === 'ADVC'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handleTxnUpdateType('ADVC')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                ADVANCE <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingHand} />
                              </Label>
                            </Col>
                            <Col xs="4" lg="4" className="pt-1">
                              <Input
                                type="radio"
                                name="accTp"
                                disabled
                                checked={txnUpdateData.accTp === 'CRDT'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handleTxnUpdateType('CRDT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                CREDIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHoldingDollar} />
                              </Label>
                            </Col>
                            <Col xs="4" lg="4" className="pt-1">
                              <Input
                                type="radio"
                                name="accTp"
                                disabled
                                checked={txnUpdateData.accTp === 'DBT'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handleTxnUpdateType('DBT')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                DEBIT <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faHandHolding} />
                              </Label>
                            </Col>
                            {txnUpdateData.accTp === 'DBT'
                              ? (
                                <Col xs="12" className="mx-1">
                                  <h6 className="print-status-pending my-1 pt-2">Transaction To<span className="mandate-star">*</span></h6>
                                  <Row className="pt-1 mx-1">
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'PRNT'}
                                        className="acps-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('PRNT')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        Printer
                                      </Label>
                                    </Col>
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'VNDR1'}
                                        className="acps-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('VNDR1')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        Vendor#1
                                      </Label>
                                    </Col>
                                    <Col xs="4" lg="4">
                                      <Input
                                        type="radio"
                                        name="dbtTp"
                                        checked={txnUpdateData.dbtTp === 'RZENT'}
                                        className="acps-radio-box cursor-pointer my-1"
                                        onChange={() => handleUpdateDbtType('RZENT')}
                                      />
                                      <Label check className="credit-tp-text mx-1">
                                        RZ Ent.
                                      </Label>
                                    </Col>
                                  </Row>
                                </Col>
                              ) : null }
                          </Row>
                        </Col>
                        <Col lg="7">
                          <div className="pt-4">
                            <h6 className="input-header-text">Transaction Amount(Rs /-)<span className="mandate-star">*</span></h6>
                            <Input
                              type="number"
                              name="amnt"
                              value={txnUpdateData.amnt}
                              required
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="5">
                          <div className="pt-4">
                            <h6 className="input-header-text">Transaction Date<span className="mandate-star">*</span></h6>
                            <Input
                              type="date"
                              name="txnDate"
                              value={txnUpdateData.txnDate}
                              disabled
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <Row className="align-start pt-4">
                            <h6 className="input-header-text my-1">Payment Mode<span className="mandate-star">*</span></h6>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'CHQ'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('CHQ')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Cheque <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyCheck} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'UPI'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('UPI')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                UPI <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faWallet} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'BNK'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('BNK')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Net Banking <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faBuildingColumns} />
                              </Label>
                            </Col>
                            <Col xs="12" lg="6" className="pt-2">
                              <Input
                                type="radio"
                                name="mPay"
                                checked={txnUpdateData.mPay === 'CSH'}
                                className="acps-radio-box cursor-pointer my-1"
                                onChange={() => handlePayUpdateMode('CSH')}
                              />
                              <Label check className="credit-tp-text mx-1">
                                Cash <FontAwesomeIcon className="payment-mode-icon mx-1" icon={faMoneyBill1Wave} />
                              </Label>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg="12">
                          <div className="pt-4">
                            <h6 className="input-header-text">Transaction done by<span className="mandate-star">*</span></h6>
                            <Input
                              type="text"
                              name="txnUser"
                              value={txnUpdateData.txnUser}
                              required
                              onChange={handleFieldUpdateChangeTxn}
                              className="yocard-input-ref"
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="pt-4">
                            <h6 className="input-header-text">Comments</h6>
                            <Input
                              type="textarea"
                              name="comments"
                              value={txnUpdateData.comments}
                              onChange={handleFieldUpdateChangeTxn}
                              className="acps-text-area"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="align-center my-1 pt-3">
                        <Button className="acps-button-oval" onClick={() => updateTxnData(row.AccId)}>Update</Button>
                      </div>
                    </ModalBody>
                  </Modal>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TransactionTable;
