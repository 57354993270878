import { patchApiBodyData } from '../../utils';
import { getConfig } from '../../utils/config/config';
import { CheckRefFileOptions, CheckRefFileResponse } from './check-reffile.service.types';

const { YOCARD_API_ROOT } = getConfig();

export const CheckRefFileRequest = ({
  inputBody, requestType, token, userType, sts, isSts, cardDt, rsn, inputS3Body, dist,
}: CheckRefFileOptions): Promise<CheckRefFileResponse> => {
  if (sts === 'GNTD') {
    return patchApiBodyData(`${YOCARD_API_ROOT}?userTp=${userType}&isSts=${isSts}&cardDt=${cardDt}&rsn=${rsn}&dist=${dist}`, token, requestType, inputS3Body as unknown as BodyInit);
  }
  return patchApiBodyData(`${YOCARD_API_ROOT}?userTp=${userType}&sts=${sts}&isSts=${isSts}&cardDt=${cardDt}&rsn=${rsn}`, token, requestType, inputBody as unknown as BodyInit);
};
