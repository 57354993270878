import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './public.scss';
import LoginPrinter from '../../components/public/login/print.login';

export const LoginPrintComponent: React.FC = () => {
  const history = useNavigate();
  const homePage = () => {
    history('/');
    window.scrollTo(0, 0);
  };
  return (
    <div className="mx-3">
      <div className="align-end my-2">
        <NavLink to="/#" onClick={homePage}>
          <img style={{ height: '45px', width: 'auto' }} src="/acps.png" alt="acpsLogo" />
        </NavLink>
      </div>
      <LoginPrinter />
    </div>
  );
};
export default LoginPrintComponent;
