import React from 'react';
import {
  Col, Row,
} from 'reactstrap';
import './accounts.scss';
import { TransactionTable } from './txn-table';

export const AccountsReport: React.FC = () => (
  <div>
    <Row className="pt-2 mx-1">
      <Col xs="12" lg="12">
        <TransactionTable />
      </Col>
    </Row>
  </div>
);

export default AccountsReport;
