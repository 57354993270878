import React, { useState } from 'react';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import './print.scss';
import {
  clearSubFolCountListReq,
  getListPrinterId,
  getSubFolCountListReq,
} from '../../../../store/yoCard/actions';
import LoaderData from '../../../../utils/loader';
import { ActiveTabProps } from './print.type';
import { APIHeader, UserType } from '../../../../utils/constants';
import { getUserTpSessionStorage, getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { SubFolCountResultRes } from '../../../../services/get-s3-subFol-count/list-subFol-count.types';
import PrintReadyTable from './print-ready-table';
import { PrinterIdData } from '../../../../services/meta-data/list-printer-id/list-printer-id.types';

export const GeneratePrintFile: React.FC<ActiveTabProps> = ({ activeTab }) => {
  const dispatch = useDispatch();
  const userTp = getUserTpSessionStorage();
  const tokenData = getTokenFromLocalStorage();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertAPIMsg, setAlertAPIMsg] = useState('');
  const [dbSubFolder, setDBSubFolder] = useState<SubFolCountResultRes[]>([{
    prntUsrCd: {
      ph: '',
      UserCd: '',
      lNme: '',
      fNme: '',
      eId: '',
    },
    updtOn: '',
    costCrd: '',
    costPrint: '',
    costVendor1: '',
    gstCrd: '',
    gstPrint: '',
    gstVendor1: '',
    cSts: '',
    cnt: '',
    courId: '',
    tk: '',
    dist: {
      distNme: '',
      stCd: '',
      stNme: '',
    },
    PrntTp: '',
    updtBy: '',
    PrntId: '',
    courTp: '',
    miscCst: '',
    miscPrnt: '0',
    miscVndr1: '0',
  }]);
  const today = new Date().toISOString().split('T')[0];
  const init_today = new Date();
  init_today.setDate(init_today.getDate() - 7);
  const lastSeventhDay = init_today.toISOString().split('T')[0];
  const [inputDate, setInputDate] = useState<string>(lastSeventhDay);
  const [toDate, setToDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [uploadLoader, setUploadLoader] = useState(false);
  const getSubFolCountResponse = useSelector((state: RootState) => state.yoCard.getSubFolderList);
  const [printUserData, setPrintUserData] = useState('NA');
  const [distData, setDistData] = useState('NA');
  const [loaderPrinter, setLoaderPrinter] = useState(false);
  const [printerListData, setPrinterListData] = useState<PrinterIdData[]>([]);
  const printResData = useSelector((state: RootState) => state.yoCard.getAllPrinterId);

  const printeroptions = printerListData && printerListData.map((print) => ({
    label: print.nme,
    value: print.UsrCd,
  }));

  const selectedPrinterOptions = printeroptions && printeroptions.find(
    (option) => option.value === printUserData,
  );

  React.useEffect(() => {
    setLoaderPrinter(true);
    dispatch(getListPrinterId({
      requestType: APIHeader.REQ_ALL_PRINTER_ID,
      token: tokenData,
      userType: UserType.SADM,
    }));
  }, []);

  React.useEffect(() => {
    if (printResData.isError && printResData.message !== '') {
      setLoaderPrinter(false);
    }
  }, [printResData.data]);

  React.useEffect(() => {
    if (!printResData.isError && printResData.message === 'executed') {
      setLoaderPrinter(false);
      setPrinterListData(printResData.data);
    }
  }, [printResData.data]);

  const handleSelectPrinterId = (e:any) => {
    setPrintUserData(e.value);
  };

  const handleFieldChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputDate(e.target.value);
  };

  const handleToChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };

  const getAllPrintableFile = () => {
    setUploadLoader(true);
    dispatch(getSubFolCountListReq({
      userType: userTp,
      requestType: APIHeader.REQ_GET_ALL_PRINT_DATA,
      dist: distData,
      printId: printUserData,
      stDt: inputDate,
      endDt: toDate,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (activeTab === 'printReady') {
      setUploadLoader(true);
      dispatch(getSubFolCountListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PRINT_DATA,
        dist: 'NA',
        printId: 'NA',
        stDt: lastSeventhDay,
        endDt: today,
        token: tokenData,
      }));
    }
  }, [activeTab]);

  React.useEffect(() => {
    if (getSubFolCountResponse.isError && getSubFolCountResponse.message !== '' && activeTab === 'printReady') {
      setUploadLoader(false);
      setAlertAPIMsg(getSubFolCountResponse.message);
      setErrorShowAlert(true);
      dispatch(clearSubFolCountListReq());
    }
  }, [getSubFolCountResponse.isError, getSubFolCountResponse.message, activeTab]);

  React.useEffect(() => {
    if (!getSubFolCountResponse.isError && getSubFolCountResponse.message === 'executed' && activeTab === 'printReady') {
      setUploadLoader(false);
      setDBSubFolder(getSubFolCountResponse.prntDet);
      dispatch(clearSubFolCountListReq());
    }
  }, [getSubFolCountResponse.isError, getSubFolCountResponse.message, activeTab]);

  const handleDistChange = (e: any) => {
    setDistData(e.target.value);
  };

  return (
    <div className="align-data-print-mobile mr">
      {uploadLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertAPIMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertAPIMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mx-1 align-view-to-row pt-2 align-center">
        <h6 className="input-header-text">Print Ready File Status</h6>
      </div>
      <Row className="align-center pt-4">
        <Col lg={{ size: 11, order: 1 }} xs={{ order: 2 }}>
          <div className="print-sadmin-card-box my-2">
            <Row className="pt-1">
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">From Date<span className="mandate-star">*</span></h6>
                  <Input
                    max={today}
                    value={inputDate}
                    type="date"
                    onChange={handleFieldChangeDate}
                    className="acps-input-ref"
                  />
                  <h6 className="print-sadmin-text-lite-white mx-1 my-1">Date</h6>
                </div>
              </Col>
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">To Date<span className="mandate-star">*</span></h6>
                  <Input
                    max={today}
                    value={toDate}
                    type="date"
                    onChange={handleToChangeDate}
                    className="acps-input-ref"
                  />
                  <h6 className="print-sadmin-text-lite-white mx-1 my-1">Date</h6>
                </div>
              </Col>
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">Village/Town</h6>
                  <Input
                    type="text"
                    name="dist"
                    value={distData}
                    onChange={handleDistChange}
                    className="acps-input-ref"
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="pt-1">
                  <h6 className="input-header-text">Assigned Printer</h6>
                  <Select
                    value={printUserData === '' ? null : selectedPrinterOptions}
                    options={printeroptions}
                    onChange={handleSelectPrinterId}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({
                        ...base,
                      // position: 'relative',
                      }),
                      control: (base: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#880000' },
                        border: '1px solid #DDE0E4',
                        minHeight: '38px',
                        borderRadius: '5px',
                        padding: 1,
                        fontSize: '15px',
                        fontWeight: 400,
                        color: '#575656',
                        boxShadow: 'none',
                        backgroundColor: '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    isLoading={loaderPrinter}
                    noOptionsMessage={() => 'No User Found'}
                    placeholder="Select User Printer"
                  />
                </div>
              </Col>
              <Col lg="12">
                <div className="align-end">
                  <Button onClick={getAllPrintableFile} disabled={inputDate === ''} className="acps-button-oval">Search</Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="pt-3 table-responsive">
            {dbSubFolder && dbSubFolder.length === 0 ? (
              <div className="pt-5 align-center">
                <span className="form-collapse-card-header">No Record Found</span>
              </div>
            )
              : (
                <PrintReadyTable data={dbSubFolder} />
              ) }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GeneratePrintFile;
