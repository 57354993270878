/* eslint-disable react/no-array-index-key */
import React from 'react';
import { SATrendResult } from '../../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.types';

interface TableProps {
  data: SATrendResult[];
}

const SAdminTable: React.FC<TableProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Village/Town</th>
          <th>Card Count</th>
          <th>Current Status</th>
          <th>Last Updated</th>
        </tr>
      </thead>
      <tbody>
        {data.length && data.map((row) => (
          <tr key={`row-${row.PrntId}-${row.PrntTp}`}>
            <td><span className="td-text-status">{row.PrntId}</span></td>
            <td><span className="td-text-status">{row.PrntTp}</span><br /><span className="td-text-lite-grey">{row.stData.distNme}({row.stData.stCd})</span></td>
            <td><span className="td-text-status">{row.cnt}</span></td>
            <td><span className="td-text-status">{row.cSts}</span></td>
            <td><span className="td-text-status">{row.updtOn}</span></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SAdminTable;
