import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import { ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import {
  agntUpdtReq,
  apiError, cardStatusReq, checkUserExistReq, contactUsPostReq, delFileURLReq, genPrintUrlReq, getAgntListReq, getAllTxn, getAvatarPublish, getAWSFilesUpload, getAWSFolderList, getBilling, getCardCountStateWise, getCardDashboard, getCardMassStatus, getCardTrendStatus, getCheckRefFileReq, getDistListReq, getDownloadFileReq, getEditBillingStatus, getFileURLReq,
  getForgotPassword, getListPrinterId, getLoginToken, getPrePrintZipReq, getPrinterDashboard, getPrintId, getProfile, getRefFileListReq, getRefStatusReq, getStateListReq, getSubFolCountListReq, getSuperAdminDashboard, getSuperAdminTCount, getSuperAdminTrendSts, getTalukaAdmListReq, getTalukaCountListReq, getTalukaListReq, getTxnReport, modifyTxn, passKeyReq,
  postPrintStatusReq,
  postRefFileReq,
  postTxn,
  printReadyReq,
  putPrintReadyReq,
  registerReq, updateAgntListReq, updateAgntReq, updateAvatarPublish, updateAWSFilesUpload, updateAWSFolderList, updateBilling, updateCardCountStateWise, updateCardDashboard, updateCardMassStatus, updateCardPrintReadyReq, updateCardStatusReq, updateCardTrendStatus, updateCheckRefFileReq, updateContactUsPostReq, updateDelFileURLReq, updateDistListReq,
  updateDownloadFileReq,
  updateEditBillingStatus,
  updateFileURLReq, updateForgotPassword, updateGenPrintUrlReq, updateGetAllTxn, updateGetTxnReport, updateListPrinterId, updateModifyTxn, updatePassKeyReq, updatePostRefFileReq, updatePostTxn, updatePrePrintZipReq, updatePrinterDashboard, updatePrintId, updatePrintReadyReq, updatePrintStatusReq, updateProfile,
  updateRefFileListReq,
  updateRefStatusReq,
  updateRegisterPostReq, updateStateListReq, updateSubFolCountListReq, updateSuperAdminDashboard, updateSuperAdminTCount, updateSuperAdminTrendSts, updateTalukaAdmListReq, updateTalukaCountListReq, updateTalukaListReq, updateToken, updateUploadImageReq,
  updateUserExistReq,
  updateUserProfilePutReq, uploadImageReq, userProfilePutReq,
} from './actions';
import { GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { FileURLResults } from '../../services/get-file-url/get-file-url.service.types';
import { UserProfilePutResults } from '../../services/update-profile/update-profile.service.types';
import { PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { UploadImageResults } from '../../services/upload-image/upload-image.service.types';
import { RegisterResults } from '../../services/register/register.service.types';
import { ListStateResults } from '../../services/meta-data/list-state/list-state.service.types';
import { ListDistResults } from '../../services/meta-data/list-district/list-dist.service.types';
import { CreateAvatarResults } from '../../services/upload-avatar/create-avatar.service.types';
import { ListAgntResults } from '../../services/sadmin/agents/agent-list/list-agent.service.types';
import { AgntStatusPutResults } from '../../services/sadmin/agents/update-status/update-status.service.types';
import { CreateAWSFilesResults } from '../../services/upload-AWS-files/create-awsfiles.service.types';
import { PostRefFileResults } from '../../services/add-new-ref-file/post-reffile.service.types';
import { ListRefFileResults } from '../../services/ref-file-list/list-reffile.service.types';
import { CheckRefFileResults } from '../../services/check-ref-file/check-reffile.service.types';
import { PutPrintReadyResults } from '../../services/add-print-ready/print-ready.service.types';
import { RefStatusResults } from '../../services/req-status-ref/ref-sts.service.types';
import { PrintReadyResults } from '../../services/sadmin/admin/update-print-ready/update-print-ready.service.types';
import { CardStatusResults } from '../../services/sadmin/admin/update-card-status/update-card-status.service.types';
import { PrintStatusResults } from '../../services/update-print-status/update-print-status.service.types';
import { ListTalukaResults } from '../../services/meta-data/list-taluka/list-taluka.service.types';
import { ListTalukaAdmResults } from '../../services/meta-data/list-taluka-adm/list-taluka-adm.service.types';
import { GetAWSSubFolderResults } from '../../services/get-aws-sub-folders/get-subfolders.service.types';
import { ListTalukaCountResults } from '../../services/get-s3-loc-count/list-taluka-count.service.types';
import { DelFileURLResults } from '../../services/del-file-url/del-file-url.service.types';
import { PrePrintZipResults } from '../../services/get-pre-print-zip/get-pre-print.service.types';
import { ListSubFolCountResults } from '../../services/get-s3-subFol-count/list-subFol-count.service.types';
import { PrintLinkResults } from '../../services/sadmin/admin/generate-print-link/generate-print-link.service.types';
import { CheckUserResults } from '../../services/check_user_exist/check-user.service.types';
import { DownloadFileResults } from '../../services/get-download-file-link/get-download-file.service.types';
import { CardMassStatusResults } from '../../services/update-mass-card-status/update-mass-status.service.types';
import { CardsStateWiseResults } from '../../services/reports-all/get-cards-state-wise/card-state-wise.service.types';
import { CardDashboardResults } from '../../services/reports-all/get-cards-dashboard/card-dashboard.service.types';
import { CardTrendStatusResults } from '../../services/reports-all/get-trends-status/card-trend.service.types';
import { ListPrinterIdResults } from '../../services/meta-data/list-printer-id/list-printer-id.service.types';
import { PrintIdResults } from '../../services/update-printer-id/update-print-id.service.types';
import { PrinterDashboardResults } from '../../services/reports-all/get-printer-dashboard/printer-dashboard.service.types';
import { SuperAdminDashboardResults } from '../../services/reports-all/superadmin-dashboard/superadmin-dashboard/superadmin-dashboard.service.types';
import { SuperAdminTrendStsResults } from '../../services/reports-all/superadmin-dashboard/superadmin-trend-status/superadmin-trend-sts.service.types';
import { BillingResults } from '../../services/reports-all/superadmin-dashboard/superadmin-billing/billing.service.types';
import { EditBillingStatusResults } from '../../services/reports-all/superadmin-dashboard/superadmin-edit-billing-status/update-billing-status.service.types';
import { SuperAdminTCountResults } from '../../services/reports-all/superadmin-dashboard/superadmin-tcount/superadmin-tcount.service.types';
import { PostTxnResults } from '../../services/sadmin/accounts/add-transactions/post-txn.service.types';
import { UpdateTxnResults } from '../../services/sadmin/accounts/update-transactions/update-txn.service.types';
import { GetAllTxnResults } from '../../services/sadmin/accounts/get-all-transactions/get-all-txn.service.types';
import { GetTxnReportResults } from '../../services/sadmin/accounts/txn-report/get-txn-report.service.types';

export const contactUsPOSTEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(yoCardService.contactUsService(action.payload)).pipe(
    map((response : ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'CampusXcel', errorMessage: error.message })),
  )),
);

export const registerAgentEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(registerReq)),
  mergeMap((action) => from(yoCardService.registerService(action.payload)).pipe(
    map((response : RegisterResults) => updateRegisterPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Register Agent', errorMessage: error.message })),
  )),
);
export const generateTokenEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getLoginToken)),
  mergeMap((action) => from(yoCardService.genTokenService(action.payload)).pipe(
    map((response : GenTokenResults) => updateToken(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Login', errorMessage: error.message })),
  )),
);
export const getProfileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getProfile)),
  mergeMap((action) => from(yoCardService.profileService(action.payload)).pipe(
    map((response : ProfileResults) => updateProfile(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Profile', errorMessage: error.message })),
  )),
);
export const getForgotPasswordEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getForgotPassword)),
  mergeMap((action) => from(yoCardService.forgotPasswordService(action.payload)).pipe(
    map((response : ForgotPasswordResults) => updateForgotPassword(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'ForgotPassword', errorMessage: error.message })),
  )),
);
export const getFileURLEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getFileURLReq)),
  mergeMap((action) => from(yoCardService.fileURLService(action.payload)).pipe(
    map((response : FileURLResults) => updateFileURLReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'File URL Update', errorMessage: error.message })),
  )),
);

export const userProfilePutEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(userProfilePutReq)),
  mergeMap((action) => from(yoCardService.userProfilePutService(action.payload)).pipe(
    map((response : UserProfilePutResults) => updateUserProfilePutReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile Update', errorMessage: error.message })),
  )),
);

export const userProfilePicEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(uploadImageReq)),
  mergeMap((action) => from(yoCardService.userProfilePicService(action.payload)).pipe(
    map((response : UploadImageResults) => updateUploadImageReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile pic Update', errorMessage: error.message })),
  )),
);

export const passKeyEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(passKeyReq)),
  mergeMap((action) => from(yoCardService.passKeyService(action.payload)).pipe(
    map((response : PassKeyPutResults) => updatePassKeyReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Pass Key Update', errorMessage: error.message })),
  )),
);
export const getStateListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getStateListReq)),
  mergeMap((action) => from(yoCardService.stateListService(action.payload)).pipe(
    map((response : ListStateResults) => updateStateListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'State Meta', errorMessage: error.message })),
  )),
);
export const getDistListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getDistListReq)),
  mergeMap((action) => from(yoCardService.distListService(action.payload)).pipe(
    map((response : ListDistResults) => updateDistListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'District Meta', errorMessage: error.message })),
  )),
);
export const updateAvatarPublishEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getAvatarPublish)),
  mergeMap((action) => from(yoCardService.avatarPublishService(action.payload)).pipe(
    map((response : CreateAvatarResults) => updateAvatarPublish(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update AVATAR', errorMessage: error.message })),
  )),
);

export const getAgntListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getAgntListReq)),
  mergeMap((action) => from(yoCardService.agntListService(action.payload)).pipe(
    map((response : ListAgntResults) => updateAgntListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Agent List', errorMessage: error.message })),
  )),
);
export const updateAgntStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(agntUpdtReq)),
  mergeMap((action) => from(yoCardService.agntStatusUpdtService(action.payload)).pipe(
    map((response : AgntStatusPutResults) => updateAgntReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Agent Status', errorMessage: error.message })),
  )),
);
export const awsFilesUploadEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getAWSFilesUpload)),
  mergeMap((action) => from(yoCardService.awsFilesUploadService(action.payload)).pipe(
    map((response : CreateAWSFilesResults) => updateAWSFilesUpload(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Upload AWS Files', errorMessage: error.message })),
  )),
);

export const addRefFileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(postRefFileReq)),
  mergeMap((action) => from(yoCardService.postRefFileService(action.payload)).pipe(
    map((response : PostRefFileResults) => updatePostRefFileReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Add New Refrence Files', errorMessage: error.message })),
  )),
);
export const getRefFileListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getRefFileListReq)),
  mergeMap((action) => from(yoCardService.refFileListService(action.payload)).pipe(
    map((response : ListRefFileResults) => updateRefFileListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Reference File list', errorMessage: error.message })),
  )),
);
export const getCheckRefFileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getCheckRefFileReq)),
  mergeMap((action) => from(yoCardService.checkRefFileService(action.payload)).pipe(
    map((response : CheckRefFileResults) => updateCheckRefFileReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Check Reference File Names', errorMessage: error.message })),
  )),
);
export const putPrintReadyEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(putPrintReadyReq)),
  mergeMap((action) => from(yoCardService.putPrintReadyService(action.payload)).pipe(
    map((response : PutPrintReadyResults) => updatePrintReadyReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Print Ready', errorMessage: error.message })),
  )),
);

export const refStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getRefStatusReq)),
  mergeMap((action) => from(yoCardService.refStatusService(action.payload)).pipe(
    map((response : RefStatusResults) => updateRefStatusReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Reference Update', errorMessage: error.message })),
  )),
);

export const genPrintUrlEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(genPrintUrlReq)),
  mergeMap((action) => from(yoCardService.genPrintUrlService(action.payload)).pipe(
    map((response : PrintLinkResults) => updateGenPrintUrlReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Reference Update', errorMessage: error.message })),
  )),
);

export const cardStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(cardStatusReq)),
  mergeMap((action) => from(yoCardService.cardStatusService(action.payload)).pipe(
    map((response : CardStatusResults) => updateCardStatusReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Card Status Change', errorMessage: error.message })),
  )),
);

export const printReadyEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(printReadyReq)),
  mergeMap((action) => from(yoCardService.printReadyService(action.payload)).pipe(
    map((response : PrintReadyResults) => updateCardPrintReadyReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Card Printing', errorMessage: error.message })),
  )),
);

export const printStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(postPrintStatusReq)),
  mergeMap((action) => from(yoCardService.postPrintStatusService(action.payload)).pipe(
    map((response : PrintStatusResults) => updatePrintStatusReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Card Update Status', errorMessage: error.message })),
  )),
);
export const getTalukaListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getTalukaListReq)),
  mergeMap((action) => from(yoCardService.talukaListService(action.payload)).pipe(
    map((response : ListTalukaResults) => updateTalukaListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Taluka Meta', errorMessage: error.message })),
  )),
);
export const getTalukaAdmListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getTalukaAdmListReq)),
  mergeMap((action) => from(yoCardService.talukabyAdmListService(action.payload)).pipe(
    map((response : ListTalukaAdmResults) => updateTalukaAdmListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Taluka by Admin Meta', errorMessage: error.message })),
  )),
);

export const getTalukaCntListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getTalukaCountListReq)),
  mergeMap((action) => from(yoCardService.talukabyLocListService(action.payload)).pipe(
    map((response : ListTalukaCountResults) => updateTalukaCountListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Taluka by Location Meta', errorMessage: error.message })),
  )),
);

export const awsFolderListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getAWSFolderList)),
  mergeMap((action) => from(yoCardService.awsFolderListService(action.payload)).pipe(
    map((response : GetAWSSubFolderResults) => updateAWSFolderList(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get AWS Folder List', errorMessage: error.message })),
  )),
);

export const getSubFolCntListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getSubFolCountListReq)),
  mergeMap((action) => from(yoCardService.subFolListService(action.payload)).pipe(
    map((response : ListSubFolCountResults) => updateSubFolCountListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'SubFolder details', errorMessage: error.message })),
  )),
);

export const delFileURLEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(delFileURLReq)),
  mergeMap((action) => from(yoCardService.delFileURLService(action.payload)).pipe(
    map((response : DelFileURLResults) => updateDelFileURLReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'File URL Delete', errorMessage: error.message })),
  )),
);

export const prePrintZipEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getPrePrintZipReq)),
  mergeMap((action) => from(yoCardService.prePrintZipService(action.payload)).pipe(
    map((response : PrePrintZipResults) => updatePrePrintZipReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Pre Print Zip', errorMessage: error.message })),
  )),
);

export const checkUserEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(checkUserExistReq)),
  mergeMap((action) => from(yoCardService.checkUserService(action.payload)).pipe(
    map((response : CheckUserResults) => updateUserExistReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User Exist', errorMessage: error.message })),
  )),
);

export const downloadFileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getDownloadFileReq)),
  mergeMap((action) => from(yoCardService.downloadFileService(action.payload)).pipe(
    map((response : DownloadFileResults) => updateDownloadFileReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Download Files from S3', errorMessage: error.message })),
  )),
);

export const cardMassStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getCardMassStatus)),
  mergeMap((action) => from(yoCardService.cardMassStatusService(action.payload)).pipe(
    map((response : CardMassStatusResults) => updateCardMassStatus(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Card Mass Status', errorMessage: error.message })),
  )),
);

export const cardCountStateWiseEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getCardCountStateWise)),
  mergeMap((action) => from(yoCardService.cardCountStateWiseService(action.payload)).pipe(
    map((response : CardsStateWiseResults) => updateCardCountStateWise(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Card Count Statewise', errorMessage: error.message })),
  )),
);

export const cardDashboardEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getCardDashboard)),
  mergeMap((action) => from(yoCardService.cardDashboardWiseService(action.payload)).pipe(
    map((response : CardDashboardResults) => updateCardDashboard(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Card Dashboard data', errorMessage: error.message })),
  )),
);

export const getAllPrinterIdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getListPrinterId)),
  mergeMap((action) => from(yoCardService.getAllPrinterIdService(action.payload)).pipe(
    map((response : ListPrinterIdResults) => updateListPrinterId(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Printer Id', errorMessage: error.message })),
  )),
);

export const createPrinterIdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getPrintId)),
  mergeMap((action) => from(yoCardService.createPrinterIdService(action.payload)).pipe(
    map((response : PrintIdResults) => updatePrintId(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Add Printer Details Id', errorMessage: error.message })),
  )),
);

export const cardTrendStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getCardTrendStatus)),
  mergeMap((action) => from(yoCardService.cardTrendStatusService(action.payload)).pipe(
    map((response : CardTrendStatusResults) => updateCardTrendStatus(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Card Trends and status', errorMessage: error.message })),
  )),
);

export const printerDashboardEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getPrinterDashboard)),
  mergeMap((action) => from(yoCardService.printerDashboardService(action.payload)).pipe(
    map((response : PrinterDashboardResults) => updatePrinterDashboard(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Printer Dashboard', errorMessage: error.message })),
  )),
);

export const superAdminDashboardEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getSuperAdminDashboard)),
  mergeMap((action) => from(yoCardService.superAdminDashboardService(action.payload)).pipe(
    map((response : SuperAdminDashboardResults) => updateSuperAdminDashboard(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get SuperAdmin Dashboard', errorMessage: error.message })),
  )),
);

export const superAdminTrendStsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getSuperAdminTrendSts)),
  mergeMap((action) => from(yoCardService.superAdminTrendStsService(action.payload)).pipe(
    map((response : SuperAdminTrendStsResults) => updateSuperAdminTrendSts(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get SuperAdmin Trends and Status', errorMessage: error.message })),
  )),
);

export const billingEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getBilling)),
  mergeMap((action) => from(yoCardService.billingService(action.payload)).pipe(
    map((response : BillingResults) => updateBilling(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Billing Status', errorMessage: error.message })),
  )),
);

export const editBillingStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getEditBillingStatus)),
  mergeMap((action) => from(yoCardService.editBillingStatusService(action.payload)).pipe(
    map((response : EditBillingStatusResults) => updateEditBillingStatus(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Billing Status', errorMessage: error.message })),
  )),
);

export const superAdminTCountEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getSuperAdminTCount)),
  mergeMap((action) => from(yoCardService.superAdminTCountService(action.payload)).pipe(
    map((response : SuperAdminTCountResults) => updateSuperAdminTCount(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get SuperAdmin Total Cards Processed Count', errorMessage: error.message })),
  )),
);

export const postTxnEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(postTxn)),
  mergeMap((action) => from(yoCardService.createTxnService(action.payload)).pipe(
    map((response : PostTxnResults) => updatePostTxn(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Add New Transactions', errorMessage: error.message })),
  )),
);

export const updateTxnEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(modifyTxn)),
  mergeMap((action) => from(yoCardService.updateTxnService(action.payload)).pipe(
    map((response : UpdateTxnResults) => updateModifyTxn(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Transaction', errorMessage: error.message })),
  )),
);

export const getAllTxnEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getAllTxn)),
  mergeMap((action) => from(yoCardService.getAllTxnService(action.payload)).pipe(
    map((response : GetAllTxnResults) => updateGetAllTxn(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Transactions', errorMessage: error.message })),
  )),
);

export const getTxnReportEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { yoCardService }) => action$.pipe(
  filter(isActionOf(getTxnReport)),
  mergeMap((action) => from(yoCardService.getTxnReportService(action.payload)).pipe(
    map((response : GetTxnReportResults) => updateGetTxnReport(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Transactions Report', errorMessage: error.message })),
  )),
);

export const yoCardEpics = combineEpics(
  contactUsPOSTEpic,
  registerAgentEpic,
  generateTokenEpic,
  getProfileEpic,
  getForgotPasswordEpic,
  getFileURLEpic,
  userProfilePutEpic,
  userProfilePicEpic,
  passKeyEpic,
  getStateListEpic,
  getDistListEpic,
  updateAvatarPublishEpic,
  getAgntListEpic,
  updateAgntStatusEpic,
  awsFilesUploadEpic,
  addRefFileEpic,
  getRefFileListEpic,
  getCheckRefFileEpic,
  putPrintReadyEpic,
  refStatusEpic,
  genPrintUrlEpic,
  cardStatusEpic,
  printReadyEpic,
  printStatusEpic,
  getTalukaListEpic,
  getTalukaAdmListEpic,
  getTalukaCntListEpic,
  awsFolderListEpic,
  getSubFolCntListEpic,
  delFileURLEpic,
  prePrintZipEpic,
  checkUserEpic,
  downloadFileEpic,
  cardMassStatusEpic,
  cardCountStateWiseEpic,
  cardDashboardEpic,
  cardTrendStatusEpic,
  getAllPrinterIdEpic,
  createPrinterIdEpic,
  printerDashboardEpic,
  superAdminDashboardEpic,
  superAdminTrendStsEpic,
  billingEpic,
  editBillingStatusEpic,
  superAdminTCountEpic,
  postTxnEpic,
  updateTxnEpic,
  getAllTxnEpic,
  getTxnReportEpic,
);
