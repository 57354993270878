import React from 'react';
import './accounts.scss';
import { BillingTable } from './accounts-billing';
import { AccountsReport } from './accounts-report';

export const AcountsExclusive: React.FC = () => (
  <div>
    <div className="mb-2">
      <span className="acps-header-with-bg">
        Quick View - Accounts
      </span>
    </div>
    <div className="pt-5">
      <AccountsReport />
    </div>
    <div className="pt-5">
      <div
        style={{
          backgroundColor: '#f6eeeb', width: '100%', padding: '3px', marginTop: '10px',
        }}
        className="my-4 align-center"
      >
        <span className="mx-2">Account/Billing Information</span>
      </div>
      <div className="mx-1">
        <BillingTable />
      </div>
    </div>
  </div>
);

export default AcountsExclusive;
