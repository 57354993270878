import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const AboutACPS: React.FC = () => (
  <div className="about-yoCard-bg my-3">
    <Row className="mx-1 align-center">
      <Col lg={{ size: 10, order: 1 }} md={{ size: 1, order: 2 }} xs={{ order: 1 }}>
        <div className="text-center-aligned">
          <span className="display-5 text-intro-sub-header">
            Bulk Card Generator <br />
          </span>
          <span className="text-intro-sub-header-grey">
            Effortlessly prepare and print bulk cards from your PDF in three simple steps:
            Upload, Generate, and Print.
          </span>
          <div className="pt-5 text-center-aligned">
            <span className="text-about pt-5">
              Acquire your ready-to-print PDF cards, perfect for efficiently converting bulk
              PDF data into printable layouts. Furthermore, efficiently supervise employee
              assignments across diverse domains to ensure smooth task administration and
              improved oversight of team activities.
            </span>
          </div>
        </div>
      </Col>
      <Col lg={{ size: 6, order: 2 }} md={{ size: 5, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center pt-1">
          <CardImg
            alt="..."
            // className="acps-image-about"
            src="images/home/about.png"
          />
        </div>
      </Col>
    </Row>
    <div className="pt-5 my-5" />
  </div>
);

export default AboutACPS;
