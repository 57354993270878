export const API_ERROR = 'API_ERROR';
export const SHOW_DATA = 'SHOW_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONTACT_US_DATA = 'CONTACT_US_DATA';
export const UPDATE_CONTACT_US_DATA = 'UPDATE_CONTACT_US_DATA';
export const CLEAR_CONTACT_US_DATA = 'CLEAR_CONTACT_US_DATA';
export const GET_ACAD_LIST = 'GET_ACAD_LIST';
export const UPDATE_GET_ACAD_LIST = 'UPDATE_GET_ACAD_LIST';
export const REGISTER_DATA = 'REGISTER_DATA';
export const UPDATE_REGISTER_DATA = 'UPDATE_REGISTER_DATA';
export const CLEAR_REGISTER_DATA = 'CLEAR_REGISTER_DATA';
export const GET_LOGIN_TOKEN = 'GET_LOGIN_TOKEN';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD';
export const UPDATE_FORGOT_PASSWORD = 'UPDATE_FORGOT_PASSWORD';
export const GET_FILE_URL = 'GET_FILE_URL';
export const UPDATE_GET_FILE_URL = 'UPDATE_GET_FILE_URL';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const UPDATE_CITY_LIST = 'UPDATE_CITY_LIST';
export const USER_PROFILE_PUT_DATA = 'USER_PROFILE_PUT_DATA';
export const UPDATE_USER_PROFILE_PUT_DATA = 'UPDATE_USER_PROFILE_PUT_DATA';
export const CLEAR_USER_PROFILE_PUT_DATA = 'CLEAR_USER_PROFILE_PUT_DATA';
export const PASS_KEY_DATA = 'PASS_KEY_DATA';
export const UPDATE_PASS_KEY_DATA = 'UPDATE_PASS_KEY_DATA';
export const CLEAR_PASS_KEY_DATA = 'CLEAR_PASS_KEY_DATA';
export const UPLOAD_IMAGE_DATA = 'UPLOAD_IMAGE_DATA';
export const UPDATE_UPLOAD_IMAGE_DATA = 'UPDATE_UPLOAD_IMAGE_DATA';
export const CLEAR_UPLOAD_IMAGE_DATA = 'CLEAR_UPLOAD_IMAGE_DATA';
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const UPDATE_STATE_LIST = 'UPDATE_STATE_LIST';
export const CLEAR_STATE_LIST = 'CLEAR_STATE_LIST';
export const GET_DIST_LIST = 'GET_DIST_LIST';
export const UPDATE_DIST_LIST = 'UPDATE_DIST_LIST';
export const CLEAR_DIST_LIST = 'CLEAR_DIST_LIST';
export const GET_AVATAR_PUBLISH = 'GET_AVATAR_PUBLISH';
export const UPDATE_AVATAR_PUBLISH = 'UPDATE_AVATAR_PUBLISH';
export const DEL_AVATAR_PUBLISH = 'DEL_AVATAR_PUBLISH';
export const AGNT_DATA = 'AGNT_DATA';
export const UPDATE_AGNT_DATA = 'UPDATE_AGNT_DATA';
export const CLEAR_AGNT_DATA = 'CLEAR_AGNT_DATA';
export const GET_AGNT_LIST = 'GET_AGNT_LIST';
export const UPDATE_AGNT_LIST = 'UPDATE_AGNT_LIST';
export const CLEAR_AGNT_LIST_DATA = 'CLEAR_AGNT_LIST_DATA';
export const GET_AWS_FILES_UPLOAD = 'GET_AWS_FILES_UPLOAD';
export const UPDATE_AWS_FILES_UPLOAD = 'UPDATE_AWS_FILES_UPLOAD';
export const CLEAR_AWS_FILES_UPLOAD = 'CLEAR_AWS_FILES_UPLOAD';
export const POST_REFFILE_DATA = 'POST_REFFILE_DATA';
export const UPDATE_POST_REFFILE_DATA = 'UPDATE_POST_REFFILE_DATA';
export const CLEAR_POST_REFFILE_DATA = 'CLEAR_POST_REFFILE_DATA';
export const GET_REFFILE_LIST = 'GET_REFFILE_LIST';
export const UPDATE_REFFILE_LIST = 'UPDATE_REFFILE_LIST';
export const CLEAR_REFFILE_LIST = 'CLEAR_REFFILE_LIST';
export const GET_CHECK_REF_FILE = 'GET_CHECK_REF_FILE';
export const UPDATE_CHECK_REF_FILE = 'UPDATE_CHECK_REF_FILE';
export const CLEAR_CHECK_REF_FILE = 'CLEAR_CHECK_REF_FILE';
export const PUT_PRINT_READY_DATA = 'PUT_PRINT_READY_DATA';
export const UPDATE_PRINT_READY_DATA = 'UPDATE_PRINT_READY_DATA';
export const CLEAR_PRINT_READY_DATA = 'CLEAR_PRINT_READY_DATA';
export const GET_REFSTS_LIST = 'GET_REFSTS_LIST';
export const UPDATE_REFSTS_LIST = 'UPDATE_REFSTS_LIST';
export const CLEAR_REFSTS_LIST = 'CLEAR_REFSTS_LIST';
export const GEN_PRINT_URL = 'GEN_PRINT_URL';
export const UPDATE_GEN_PRINT_URL = 'UPDATE_GEN_PRINT_URL';
export const CLEAR_GEN_PRINT_URL = 'CLEAR_GEN_PRINT_URL';
export const CARD_STATUS = 'CARD_STATUS';
export const UPDATE_CARD_STATUS = 'UPDATE_CARD_STATUS';
export const CLEAR_CARD_STATUS = 'CLEAR_CARD_STATUS';
export const PRINT_READY = 'PRINT_READY';
export const UPDATE_PRINT_READY = 'UPDATE_PRINT_READY';
export const CLEAR_PRINT_READY = 'CLEAR_PRINT_READY';
export const PRINT_CARD_STATUS = 'PRINT_CARD_STATUS';
export const UPDATE_PRINT_CARD_STATUS = 'UPDATE_PRINT_CARD_STATUS';
export const CLEAR_PRINT_CARD_STATUS = 'CLEAR_PRINT_CARD_STATUS';
export const GET_TALUKA_LIST = 'GET_TALUKA_LIST';
export const UPDATE_TALUKA_LIST = 'UPDATE_TALUKA_LIST';
export const CLEAR_TALUKA_LIST = 'CLEAR_TALUKA_LIST';
export const GET_TALUKA_ADM_LIST = 'GET_TALUKA_ADM_LIST';
export const UPDATE_TALUKA_ADM_LIST = 'UPDATE_TALUKA_ADM_LIST';
export const CLEAR_TALUKA_ADM_LIST = 'CLEAR_TALUKA_ADM_LIST';
export const GET_AWS_FOLDER_LIST = 'GET_AWS_FOLDER_LIST';
export const UPDATE_AWS_FOLDER_LIST = 'UPDATE_AWS_FOLDER_LIST';
export const CLEAR_AWS_FOLDER_LIST = 'CLEAR_AWS_FOLDER_LIST';
export const GET_TALUKA_CNT_LIST = 'GET_TALUKA_CNT_LIST';
export const UPDATE_TALUKA_CNT_LIST = 'UPDATE_TALUKA_CNT_LIST';
export const CLEAR_TALUKA_CNT_LIST = 'CLEAR_TALUKA_CNT_LIST';
export const DEL_FILE_URL = 'DEL_FILE_URL';
export const UPDATE_DEL_FILE_URL = 'UPDATE_DEL_FILE_URL';
export const CLEAR_DEL_FILE_URL = 'CLEAR_DEL_FILE_URL';
export const GET_PRE_PRINT_ZIP = 'GET_PRE_PRINT_ZIP';
export const UPDATE_PRE_PRINT_ZIP = 'UPDATE_PRE_PRINT_ZIP';
export const CLEAR_PRE_PRINT_ZIP = 'CLEAR_PRE_PRINT_ZIP';
export const GET_SUB_FOL_CNT_LIST = 'GET_SUB_FOL_CNT_LIST';
export const UPDATE_SUB_FOL_CNT_LIST = 'UPDATE_SUB_FOL_CNT_LIST';
export const CLEAR_SUB_FOL_CNT_LIST = 'CLEAR_SUB_FOL_CNT_LIST';
export const CHK_USR_EXIST = 'CHK_USR_EXIST';
export const UPDATE_USR_EXIST = 'UPDATE_USR_EXIST';
export const CLEAR_USR_EXIST = 'CLEAR_USR_EXIST';
export const GET_DOWNLOAD_FILE = 'GET_DOWNLOAD_FILE';
export const UPDATE_DOWNLOAD_FILE = 'UPDATE_DOWNLOAD_FILE';
export const CLEAR_DOWNLOAD_FILE = 'CLEAR_DOWNLOAD_FILE';
export const CARD_MASS_STATUS = 'CARD_MASS_STATUS';
export const UPDATE_CARD_MASS_STATUS = 'UPDATE_CARD_MASS_STATUS';
export const CLEAR_CARD_MASS_STATUS = 'CLEAR_CARD_MASS_STATUS';
export const CARD_COUNT_STATE_WISE = 'CARD_COUNT_STATE_WISE';
export const UPDATE_CARD_COUNT_STATE_WISE = 'UPDATE_CARD_COUNT_STATE_WISE';
export const CLEAR_CARD_COUNT_STATE_WISE = 'CLEAR_CARD_COUNT_STATE_WISE';
export const CARD_DASHBOARD = 'CARD_DASHBOARD';
export const UPDATE_CARD_DASHBOARD = 'UPDATE_CARD_DASHBOARD';
export const CLEAR_CARD_DASHBOARD = 'CLEAR_CARD_DASHBOARD';
export const CARD_TREND_STATUS = 'CARD_TREND_STATUS';
export const UPDATE_CARD_TREND_STATUS = 'UPDATE_CARD_TREND_STATUS';
export const CLEAR_CARD_TREND_STATUS = 'CLEAR_CACLEAR_CARD_TREND_STATUSRD_DASHBOARD';
export const ALL_PRINTER_ID = 'ALL_PRINTER_ID';
export const UPDATE_ALL_PRINTER_ID = 'UPDATE_ALL_PRINTER_ID';
export const CLEAR_ALL_PRINTER_ID = 'CLEAR_ALL_PRINTER_ID';
export const CREATE_PRINT_ID = 'CREATE_PRINT_ID';
export const UPDATE_CREATE_PRINT_ID = 'UPDATE_CREATE_PRINT_ID';
export const CLEAR_CREATE_PRINT_ID = 'CLEAR_CREATE_PRINT_ID';
export const PRINTER_DASHBOARD = 'PRINTER_DASHBOARD';
export const UPDATE_PRINTER_DASHBOARD = 'UPDATE_PRINTER_DASHBOARD';
export const CLEAR_PRINTER_DASHBOARD = 'CLEAR_PRINTER_DASHBOARD';
export const SUPERADMIN_DASHBOARD = 'SUPERADMIN_DASHBOARD';
export const UPDATE_SUPERADMIN_DASHBOARD = 'UPDATE_SUPERADMIN_DASHBOARD';
export const CLEAR_SUPERADMIN_DASHBOARD = 'CLEAR_SUPERADMIN_DASHBOARD';
export const SUPERADMIN_TREND_STS = 'SUPERADMIN_TREND_STS';
export const UPDATE_SUPERADMIN_TREND_STS = 'UPDATE_SUPERADMIN_TREND_STS';
export const CLEAR_SUPERADMIN_TREND_STS = 'CLEAR_SUPERADMIN_TREND_STS';
export const BILLING = 'BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const CLEAR_BILLING = 'CLEAR_BILLING';
export const EDIT_BILLING_STS = 'EDIT_BILLING_STS';
export const UPDATE_EDIT_BILLING_STS = 'UPDATE_EDIT_BILLING_STS';
export const CLEAR_EDIT_BILLING_STS = 'CLEAR_EDIT_BILLING_STS';
export const SUPERADMIN_T_COUNT = 'SUPERADMIN_T_COUNT';
export const UPDATE_SUPERADMIN_T_COUNT = 'UPDATE_SUPERADMIN_T_COUNT';
export const CLEAR_SUPERADMIN_T_COUNT = 'CLEAR_SUPERADMIN_T_COUNT';
export const POST_TXN = 'POST_TXN';
export const UPDATE_POST_TXN = 'UPDATE_POST_TXN';
export const CLEAR_POST_TXN = 'CLEAR_POST_TXN';
export const MODIFY_TXN = 'MODIFY_TXN';
export const UPDATE_MODIFY_TXN = 'UPDATE_MODIFY_TXN';
export const CLEAR_MODIFY_TXN = 'CLEAR_MODIFY_TXN';
export const GET_ALL_TXN = 'GET_ALL_TXN';
export const UPDATE_GET_ALL_TXN = 'UPDATE_GET_ALL_TXN';
export const CLEAR_GET_ALL_TXN = 'CLEAR_GET_ALL_TXN';
export const GET_TXN_REPORT = 'GET_TXN_REPORT';
export const UPDATE_GET_TXN_REPORT = 'UPDATE_GET_TXN_REPORT';
export const CLEAR_GET_TXN_REPORT = 'CLEAR_GET_TXN_REPORT';
