import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip,
} from 'reactstrap';
import './user.scss';
import { faCircleCheck, faHourglass } from '@fortawesome/free-regular-svg-icons';
import { clearAgntListReq, getAgntListReq } from '../../../../store/yoCard/actions';
import { APIHeader } from '../../../../utils/constants';
import { getTokenFromLocalStorage, getUserTpSessionStorage } from '../../../../utils/service/localstorage-service';
import LoaderData from '../../../../utils/loader';
import { errorAlert } from '../../../../utils/alert';
import { AgntData } from '../../../../services/sadmin/agents/agent-list/list-agent.types';
import RevokedUser from './revoked-user';
import { PendingUser } from './pending-user';
import { ApprovedUser } from './approved-user';

export const AgentStats: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userTp = getUserTpSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('pending');
  const [loaderAgent, setLoaderAgent] = React.useState(false);
  const listAgent = useSelector((state: RootState) => state.yoCard.getAgntList);
  const [agntData, setAgntData] = React.useState<AgntData>({
    tCnt: 0,
    fCnt: -1,
    message: '',
    agntData: [],
  });
  const handlePending = () => {
    setLoaderAgent(true);
    setActiveTab('pending');
    dispatch(getAgntListReq({
      userType: userTp,
      requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
      status: 'PADM',
      // limit: ApiLIMIT.LIMIT,
      token: tokenData,
      // searchData: '',
    }));
  };
  const handleApproved = (e: any) => {
    e.preventDefault();
    setLoaderAgent(true);
    setActiveTab('approved');
    dispatch(getAgntListReq({
      userType: userTp,
      requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
      status: 'APPR',
      // limit: ApiLIMIT.LIMIT,
      token: tokenData,
      // searchData: '',
    }));
  };
  // const handlePending = () => {
  //   setLoaderAgent(true);
  //   Promise.resolve(
  //     dispatch(clearAgntListReq()),
  //   ).then(() => {
  //     dispatch(getAgntListReq({
  //       userType: userTp,
  //       requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
  //       status: 'PADM',
  //       // limit: ApiLIMIT.LIMIT,
  //       token: tokenData,
  //       // searchData: '',
  //     }));
  //   }).then(() => {
  //     setActiveTab('pending');
  //   });
  // };

  // const handleApproved = () => {
  //   setLoaderAgent(true);
  //   Promise.resolve(
  //     setActiveTab('completed'),
  //   ).then(() => {
  //     dispatch(getAgntListReq({
  //       userType: userTp,
  //       requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
  //       status: 'APPR',
  //       // limit: ApiLIMIT.LIMIT,
  //       token: tokenData,
  //       // searchData: '',
  //     }));
  //   }).then(() => {
  //     dispatch(clearAgntListReq());
  //   });
  // };

  const handleRejected = () => {
    setLoaderAgent(true);
    Promise.resolve(
      dispatch(clearAgntListReq()),
    ).then(() => {
      dispatch(getAgntListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
        status: 'NACC',
        // limit: ApiLIMIT.LIMIT,
        token: tokenData,
        // searchData: '',
      }));
    }).then(() => {
      setActiveTab('revoked');
    });
  };

  React.useEffect(() => {
    setLoaderAgent(true);
    if (activeTab === 'pending') {
      dispatch(getAgntListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_AGNT_STS,
        status: 'PADM',
        // limit: ApiLIMIT.LIMIT,
        token: tokenData,
        // searchData: '',
      }));
    }
  }, []);
  React.useEffect(() => {
    if (listAgent.isError && listAgent.message !== '') {
      setLoaderAgent(false);
      setAlertMsg(listAgent.message);
      setErrorShowAlert(true);
    }
  }, [listAgent.isError, listAgent.message]);
  React.useEffect(() => {
    if (!listAgent.isError && listAgent.message === 'success') {
      setLoaderAgent(false);
      setAgntData(listAgent);
      dispatch(clearAgntListReq());
    }
  }, [listAgent.isError, listAgent.message, listAgent.agntData]);

  return (
    <div>
      { loaderAgent ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-2">
        <span className="acps-header-with-bg">
          Quick View - Agent Status
        </span>
      </div>
      <Row className="pt-2">
        <Col xs="12" className="pt-2">
          <Nav tabs className="user-sadmin-profile-tab">
            <div className="user-sadmin-row-profile-tab">
              <div className="user-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'pending'} onClick={handlePending}>
                    <FontAwesomeIcon icon={faHourglass} className="user-sadmin-tab-profile-icon" />
                    <span>Pending</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'revoked'} onClick={handleRejected}>
                    <FontAwesomeIcon icon={faBan} className="user-sadmin-tab-profile-icon" />
                    <span className="user-sadmin-tab-text">Revoked</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'approved'} onClick={(e) => handleApproved(e)}>
                    <FontAwesomeIcon icon={faCircleCheck} className="user-sadmin-tab-profile-icon" />
                    <span className="user-sadmin-tab-text">Approved</span>
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="user-sadmin-row-profile-tab-mobile">
              <div className="user-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer user-sadmin-profile-tab">
                  <NavLink id="pendingId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'pending'} onClick={handlePending}>
                    <FontAwesomeIcon icon={faHourglass} className="user-sadmin-tab-profile-icon" />
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="pendingId">
                    Pending
                  </UncontrolledTooltip>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin user-sadmin-profile-tab">
                  <NavLink id="revokedId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'revoked'} onClick={handleRejected}>
                    <FontAwesomeIcon icon={faBan} className="user-sadmin-tab-profile-icon" />
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="revokedId">
                    Revoked
                  </UncontrolledTooltip>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin user-sadmin-profile-tab">
                  <NavLink id="completedId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'approved'} onClick={handleApproved}>
                    <FontAwesomeIcon icon={faCircleCheck} className="user-sadmin-tab-profile-icon" />
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="completedId">
                    Approved
                  </UncontrolledTooltip>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'pending' && (
            <TabPane tabId="pending">
              <PendingUser agntData={agntData} />
            </TabPane>
            )}
            {activeTab === 'approved' && (
            <TabPane tabId="approved">
              <ApprovedUser agntData={agntData} />
            </TabPane>
            )}
            {activeTab === 'revoked' && (
            <TabPane tabId="revoked">
              <RevokedUser agntData={agntData} />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default AgentStats;
