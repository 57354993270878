import { SuperAdminTCountResponse, SuperAdminTCountResults } from './superadmin-tcount.service.types';

export const SuperAdminTCountTransform: (response: SuperAdminTCountResponse) => SuperAdminTCountResults = (superAdminTCountResults) => {
  const { data, is_error, message } = superAdminTCountResults;
  const result: SuperAdminTCountResults = {
    data: {
      tCardCnt: '',
      tCardCst: '',
      tCardGST: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data.tCardCnt = data.tCardCnt ?? '0';
    result.data.tCardCst = data.tCardCst ?? '';
    result.data.tCardGST = data.tCardGST ?? '';
    result.message = 'executed';
  }
  return result;
};
