import { GetAllTxnResponse, GetAllTxnResults } from './get-all-txn.service.types';

export const GetAllTxnTransform: (response: GetAllTxnResponse) => GetAllTxnResults = (getAllTxnResults) => {
  const { data, is_error, message } = getAllTxnResults;
  const result: GetAllTxnResults = {
    data: {
      accDetails: [{
        AccId: '',
        AccTp: '',
        amnt: '',
        mPayVal: '',
        accTpVal: '',
        dbtTpVal: '',
        accTp: '',
        txnUser: '',
        mPay: '',
        comments: '',
        dbtTp: '',
        // prntCd: '',
        // prntDt: '',
        cardDet: [{
          CrdDt: '',
          CrdCd: '',
          tCnt: '',
          stData: {
            distNme: '',
            stCd: '',
            stNme: '',
          },
        }],
      }],
      advDetail: {
        tAdvBal: 0,
        advData: [
          {
            AccId: '',
            AccTp: '',
            mBal: '',
            txnDt: '',
            mPay: '',
            crdtBal: 0,
            prntBal: 0,
            rzBal: 0,
            vndr1Bal: 0,
            accTp: '',
            txnUser: '',
            comments: '',
          },
        ],
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};
