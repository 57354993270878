import {
  Button,
  Card, CardBody, CardFooter,
} from 'reactstrap';

export const errorAlert = (isRefresh: boolean, errorMessage: any, closeAlert: Boolean, setIsFieldEmpty: any) => {
  const clearAlert = () => {
    setIsFieldEmpty(!closeAlert);
  };
  const clearAlertRefresh = () => {
    setIsFieldEmpty(!closeAlert);
    window.location.reload();
  };
  return (
    <div className="loader-campusxcel">
      <Card style={{ maxWidth: '500px', border: '1px solid #f2e0aa', boxShadow: '5px 5px 10px rgb(20 55 183 / 20%)' }}>
        <CardBody>
          <div className="align-center mx-4">
            <span className="alert-error">{errorMessage}</span>
          </div>
        </CardBody>
        <CardFooter style={{ background: 'white', borderTop: 'none' }}>
          <div className="align-end">
            {isRefresh
              ? (
                <Button
                  onClick={() => {
                    clearAlertRefresh();
                  }}
                  className="acps-button-oval"
                >close
                </Button>
              )
              : (
                <Button
                  onClick={() => {
                    clearAlert();
                  }}
                  className="acps-button-oval"
                >close
                </Button>
              )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export const successAlert = (isRefresh: boolean, message: any, closeAlert: Boolean, setIsFieldEmpty: any) => {
  const clearAlert = () => {
    setIsFieldEmpty(!closeAlert);
  };
  const clearAlertRefresh = () => {
    setIsFieldEmpty(!closeAlert);
    window.location.reload();
  };
  return (
    <div className="loader-campusxcel">
      <Card style={{
        maxWidth: '400px', background: '#fcfcf5', border: '1px solid #fcfcb6', boxShadow: '0px 6px 8px rgb(0 55 183 / 18%)',
      }}
      >
        <CardBody>
          <div className="align-center mx-2">
            <span className="alert-success">{message}</span>
          </div>
        </CardBody>
        <CardFooter style={{ background: '#fcfcf5', borderTop: 'none' }}>
          <div className="align-end">
            {isRefresh
              ? (
                <Button
                  onClick={() => {
                    clearAlertRefresh();
                  }}
                  className="acps-button-oval"
                >close
                </Button>
              )
              : (
                <Button
                  onClick={() => {
                    clearAlert();
                  }}
                  className="acps-button-oval"
                >close
                </Button>
              )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
